<template>
  <div class="order-create__section">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
                        'ex_packaging',
                        'ex_packagingMaxSize',
                      ])"></div>
      {{$t('ex_packaging.localization_value.value')}}
      <span class="capt" v-if="ex.express.type === DHL_DELIVERY_SERVICE.id">
          {{$t('ex_packagingMaxSize.localization_value.value')}}
        </span>
    </div>

    <PackagingBlock
            :packaging="ex.express.packaging"
            :userId="parseInt(ex.express.userId)"
            :canUseScale="_.has(currentPermissions, PERMISSIONS.USER_CAN_USE_SCALES) || isAdmin"
            :allDisabled="ex.express.needPayFromFastReport"
    />

    <template v-if="_.has(ex.express.user, 'id') && ex.getExpressType() === FEDEX_DELIVERY_SERVICE.id && checkFedexPackaging">
<!--      <div class="order-create__section-label section-label"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['express_fedexPacking'])"></div>-->
<!--        {{$t('express_fedexPacking.localization_value.value')}}-->
<!--      </div>-->

      <div class="order-create__product custom-row">
        <div class="order-create__product-col custom-col custom-col--50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
						FEDEX_PACKAGING_TYPES.HIMSELF_FEDEX_PACKAGING_TYPE.translate,
            FEDEX_PACKAGING_TYPES.FEDEX_PACKAGING_TYPE.translate,
            FEDEX_PACKAGING_TYPES.ENVELOPE_FEDEX_PACKAGING_TYPE.translate,
					])"></div>
          <DefaultSelect
              :options="fedexPacking"
              :label="$t('express_fedexPacking.localization_value.value')"
              :otherValue="'translation'"
              :selected="ex.express.fedexPackingType"
              :clearable="false"
              @change="changeFedexPackingType"
          />
        </div>
      </div>
    </template>

    <div class="order-create__row custom-row" v-if="ex.getExpressType() !== NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id">
      <div class="order-create__row custom-col custom-col--33 custom-col--md-100  direction-column"
           v-if="ex.express.type !== DHL_DELIVERY_SERVICE.id || (!isAdmin && (isUserAlternativeContactPoland || isPoland)) || isWarehousePyrzowice"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
                        'express_INeedInsurance',
                        'express_InsurancePrice',
                      ])"></div>
        <DefaultCheckbox
                v-model="ex.express.packaging.packaging.insurance"
                :label="$t('express_INeedInsurance.localization_value.value')"
                @click="ex.express.packaging.packaging.insurance = !ex.express.packaging.packaging.insurance"
        />

        <DefaultInput
                class="mt-4 ml-4"
                :label="$t('express_InsurancePrice.localization_value.value')"
                v-bind:class="{
                  'ui-no-valid': ex.express.packaging.validation.insurancePrice,
                  required : ex.express.packaging.packaging.insurance
                }"
                :errorTxt="$t(`${ex.express.packaging.validationTranslate.insurancePrice}.localization_value.value`) +
                  ' ' + ex.express.packaging.validationTxt.insurancePriceMax"
                :error="ex.express.packaging.validation.insurancePrice"
                v-model="ex.express.packaging.packaging.insurancePrice"
                :disabled="!ex.express.packaging.packaging.insurance"
        />
      </div>

      <div class="order-create__row custom-col custom-col--33 custom-col--md-100 d-flex align-items-end"
           v-if="isEdit && isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
                        'express_TrackingNumber',
                      ])"></div>
        <DefaultInput
                class="w-100"
                :label="$t('express_TrackingNumberCDEKNP.localization_value.value')"
                v-model="ex.express.systemTrackingNumber"
        />
      </div>
    </div>


  </div>
</template>

<script>
  import DefaultInput from '../../../../../../UI/inputs/DefaultInput/DefaultInput.vue'
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox.vue"
  import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
  import {expressMixinsFromCard} from "../../../../../../../mixins/expressMixins/expressMixin";
  import PackagingBlock from "../../../../../../coreComponents/PackagingBlock/PackagingBlock";
  import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
  import {
    FEDEX_DELIVERY_SERVICE,
    FEDEX_PACKAGING_TYPES,
    DHL_DELIVERY_SERVICE,
    NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE
  } from "@/staticData/staticVariables";


  export default {
    name: "Packaging",
    components: {
      DefaultSelect,
      PackagingBlock,
      DefaultInput,
      DefaultCheckbox,
    },

    mixins: [mixinDetictingMobile, expressMixinsFromCard],

    props: {
      ex: Object,
      serverError: {
        type: Boolean,
        default: false,
      },
      hideAddProfile: {
        type: Boolean,
        default: false
      },
      isEdit: {
        type: Boolean,
        default: false,
      }
    },

    data() {
      return {
        options: [{}],

        fedexPacking: [
          {
            // name: this.$t(`${FEDEX_PACKAGING_TYPES.HIMSELF_FEDEX_PACKAGING_TYPE.translate}.localization_value.value`),
            translation: FEDEX_PACKAGING_TYPES.HIMSELF_FEDEX_PACKAGING_TYPE.translate,
            value: FEDEX_PACKAGING_TYPES.HIMSELF_FEDEX_PACKAGING_TYPE.value
          },
          {
            // name: this.$t(`${FEDEX_PACKAGING_TYPES.FEDEX_PACKAGING_TYPE.translate}.localization_value.value`),
            translation: FEDEX_PACKAGING_TYPES.FEDEX_PACKAGING_TYPE.translate,
            value: FEDEX_PACKAGING_TYPES.FEDEX_PACKAGING_TYPE.value
          },
          {
            // name: this.$t(`${FEDEX_PACKAGING_TYPES.ENVELOPE_FEDEX_PACKAGING_TYPE.translate}.localization_value.value`),
            translation: FEDEX_PACKAGING_TYPES.ENVELOPE_FEDEX_PACKAGING_TYPE.translate,
            value: FEDEX_PACKAGING_TYPES.ENVELOPE_FEDEX_PACKAGING_TYPE.value
          },
        ],

        FEDEX_PACKAGING_TYPES: FEDEX_PACKAGING_TYPES,
        FEDEX_DELIVERY_SERVICE: FEDEX_DELIVERY_SERVICE,
        DHL_DELIVERY_SERVICE: DHL_DELIVERY_SERVICE,
        NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE: NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE,
      }
    },

    computed: {
      user: function () {
        return this.ex.express.userId
      },
    },

    // watch: {
    // user: function() {
    //   this.getExpressProformPackaging()
    // },
    //
    // loadUserAuthorizedData: function() {
    //   this.getExpressProformPackaging()
    // },
    // },

    mounted() {
      if(!this.isEdit && this.ex.express.fedexPackingType === '') {
        this.ex.express.fedexPackingType = this._.first(this.fedexPacking)
      }
    },

    methods: {
      changeFedexPackingType(val) {
        this.ex.setFedexPackingType(val)
      },
    },

  }
</script>

<style scoped>
.capt {
  font-size: 14px;
  line-height: 23px;
  font-weight: 400;
  margin-left: 10px;
}
</style>

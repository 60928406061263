import {checkValid} from "./functions/ModelsValidate";

export function Packaging() {

  this.checkValid = checkValid,

  this.packaging = {
    name: '',
    weight: '',
    height: '',
    width: '',
    length: '',
    volumeWeight: '',
    volumeWeight4000: '',
    volumeGeneral: '',
    insurance: false,
    insurancePrice: '',
    userId: null,
  }

  this.validation = {
    name: false,
    weight: false,
    height: false,
    width: false,
    packageLength: false,
    volumeWeight: false,
    volumeWeight4000: false,
    insurance: false,
    insurancePrice: false,
    userId: false,
  }

  this.validationTranslate = {
    name: '',
    weight: '',
    height: '',
    width: '',
    packageLength: '',
    volumeWeight: '',
    volumeWeight4000: '',
    insurance: '',
    insurancePrice: '',
    userId: '',
  }

  this.validationTxt = {
    name: false,
    weight: false,
    height: false,
    width: false,
    packageLength: false,
    volumeWeight: false,
    volumeWeight4000: false,
    insurance: false,
    insurancePrice: false,
    insurancePriceMax: false,
    userId: false,
  }

}


/*****************   SETTERS   ******************/
Packaging.prototype.setName = function(val){
  this.packaging.name = val
  //don't reset if entering a new packaging name
  this.packaging.weight = val.weight ? val.weight.replaceAll(',', '.') : this.packaging.weight
  this.packaging.height = val.height ? val.height.replaceAll(',', '.') : this.packaging.height
  this.packaging.width = val.width ? val.width.replaceAll(',', '.') : this.packaging.width
  this.packaging['length'] = val['length'] ? val['length'].replaceAll(',', '.') : this.packaging['length']
  this.calcVolumeWeight()
  this.calcVolumeGeneral()
}

Packaging.prototype.calcVolumeWeight = function(){
  if(
    this.packaging.height.length > 0 &&
    this.packaging.width.length > 0 &&
    this.packaging['length'].length > 0
  ){
    let calculate = parseFloat((
      this.packaging.height.replaceAll(',', '.') *
      this.packaging.width.replaceAll(',', '.') *
      this.packaging['length'].replaceAll(',', '.')
    ) / 5000)

    let calculate4000 = parseFloat((
      this.packaging.height.replaceAll(',', '.') *
      this.packaging.width.replaceAll(',', '.') *
      this.packaging['length'].replaceAll(',', '.')
    ) / 4000)

    if(calculate < 0.01)
      this.packaging.volumeWeight = calculate.toFixed(4)
    else
      this.packaging.volumeWeight = calculate.toFixed(2)

    if(calculate4000 < 0.01)
      this.packaging.volumeWeight4000 = calculate.toFixed(4)
    else
      this.packaging.volumeWeight4000 = calculate.toFixed(2)

  } else {
    this.packaging.volumeWeight = ''
    this.packaging.volumeWeight4000 = ''
  }
}

Packaging.prototype.calcVolumeGeneral = function(){
  if(
    this.packaging.height.length > 0 &&
    this.packaging.width.length > 0 &&
    this.packaging['length'].length > 0
  ){
    this.packaging.volumeGeneral = parseFloat((
      this.packaging.height.replaceAll(',', '.') *
      this.packaging.width.replaceAll(',', '.') *
      this.packaging['length'].replaceAll(',', '.')
    ) / 1000000).toFixed(3)

  } else {
    this.packaging.volumeGeneral = ''
  }
}

Packaging.prototype.setWeight = function(val){
  this.packaging.weight = val;
}

Packaging.prototype.setHeight = function(val){
  this.packaging.height = val;
}

Packaging.prototype.setWidth = function(val){
  this.packaging.width = val;
}

Packaging.prototype.setLength = function(val){
  this.packaging.length = val;
}

Packaging.prototype.setVolumeWeight = function (val){
  this.packaging.volumeWeight = val;
}

Packaging.prototype.setVolumeWeight4000 = function (val){
  this.packaging.volumeWeight4000 = val;
}

Packaging.prototype.setInsurance = function (val){
  this.packaging.insurance = val;
}

Packaging.prototype.setInsurancePrice = function (val){
  this.packaging.insurancePrice = val;
}

Packaging.prototype.setUserId = function (val) {
  this.packaging.userId = val
}

Packaging.prototype.setPackagingItem = function (val){
  this.packaging.name = val.name ? val.name : ''
  this.packaging.weight = val.weight ? val.weight : ''
  this.packaging.height = val.height ? val.height : ''
  this.packaging.width = val.width ? val.width : ''
  this.packaging.length = val['length'] ? val['length'] : ''
  this.packaging.insurance = val.insurance ? val.insurance : ''
  this.packaging.insurancePrice = val.insurancePrice ? val.insurancePrice : ''
  this.calcVolumeWeight()
  this.calcVolumeGeneral()
}


/*****************   GETTERS   ******************/
Packaging.prototype.getName = function(){
  return this.packaging.name;
}

Packaging.prototype.getWeight = function(){
  return this.packaging.weight;
}

Packaging.prototype.getHeight = function(){
  return this.packaging.height;
}

Packaging.prototype.getWidth = function(){
  return this.packaging.width;
}

Packaging.prototype.getLength = function(){
  return this.packaging.length;
}

Packaging.prototype.getVolumeWeight = function (){
  return this.packaging.volumeWeight;
}

Packaging.prototype.getVolumeWeight4000 = function (){
  return this.packaging.volumeWeight4000;
}

Packaging.prototype.getVolumeGeneral = function (){
  return this.packaging.volumeGeneral;
}

Packaging.prototype.getInsurance = function (){
  return this.packaging.insurance;
}

Packaging.prototype.getInsurancePrice = function (){
  return this.packaging.insurancePrice;
}

Packaging.prototype.getUserId = function (){
  return this.packaging.userId;
}




/*****************   VALIDATION   ******************/

Packaging.prototype.packagingValidate = function(){

  let validationItems = {
    // name: this.packaging.name,
    weight: this.packaging.weight.replaceAll(',', '.'),
    height: this.packaging.height,
    width: this.packaging.width,
    packageLength: this.packaging['length'],
  }

  let validationOptions = {
    // name: {type: ['empty']},
    weight: {type: ['numeric', 'not-zero', 'empty']},
    height: {type: ['numeric', 'not-zero', 'empty']},
    width: {type: ['numeric', 'not-zero', 'empty']},
    packageLength: {type: ['numeric', 'not-zero', 'empty']},
  }


  if(this.packaging.insurance){
    validationItems.insurancePrice = this.packaging.insurancePrice
    validationOptions.insurancePrice = {type: ['numeric', 'not-zero', 'empty']}
  }


  return this.checkValid(validationItems, validationOptions)
}

Packaging.prototype.insuranceValueValidate = function(val){

  if(!this.packaging.insurance) return true

  if(parseFloat(this.getInsurancePrice()) > parseFloat(val)){
    this.validation.insurancePrice = true
    this.validationTranslate.insurancePrice = 'error_maxInsurance'
    this.validationTxt.insurancePriceMax = parseFloat(val)
    return false
  }

  return true
}

Packaging.prototype.packagingPartialValidate = function(
    {
      name,
      weight,
      height,
      width,
      packageLength,
      insurance,
      integerLength
    }
){

  let validationItems = {
    name: this.packaging.name,
    weight: this.packaging.weight,
    height: this.packaging.height,
    width: this.packaging.width,
    packageLength: this.packaging['length'],
  }

  let validationOptions = {
    name: {type: name ? ['empty'] : []},
    weight: {type: weight ? ['numeric', 'empty'] : []},
    height: {type: height ? ['numeric', 'empty'] : []},
    width: {type: width ? ['numeric', 'empty'] : []},
    packageLength: {type: packageLength ? ['numeric', 'empty'] : []},
  }

  if(integerLength){
    validationOptions.packageLength.type.push('is-integer')
  }


  if(insurance && this.packaging.insurance){
    validationItems.insurancePrice = this.packaging.insurancePrice
    validationOptions.insurancePrice = {type: ['numeric', 'not-zero', 'empty']}
  }


  return this.checkValid(validationItems, validationOptions)
}




/*****************   PREPARE DATA   ******************/

Packaging.prototype.preparePackagingItem = function (volumeWeight4000 = false){

  let data = {
    "package_name": this.packaging.name.package_name,
    "weight": this.packaging.weight.replaceAll(',', '.'),
    "width": this.packaging.width.replaceAll(',', '.'),
    "length": this.packaging['length'].replaceAll(',', '.'),
    "height": this.packaging.height.replaceAll(',', '.'),
    "volumeWeight": this.packaging.volumeWeight,
    "volumeGeneral": this.packaging.volumeGeneral,
  }

  if (this.getUserId()) {
    data['user_id'] = this.getUserId()
  }

  if(volumeWeight4000){
    data['volumeWeight4000'] = this.packaging.volumeWeight4000
  }

  return data
}

<template>
  <modal
      @close="$emit('close')"
      class="address-editing-modal address-editing"
  >
    <template slot="header">
      <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit"
             @click="editTranslate(['profile_AddressEditing'])"></div>
        {{$t('profile_AddressEditing.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="address-editing__content" v-if="data.user_personal_contact">
        <div class="section-label"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit"
                @click="editTranslate(['common_address'])"></div>
          {{$t('common_address.localization_value.value')}}
        </div>

        <div class="address-editing__row custom-row" v-if="Object.keys(data).length > 0">
          <div class="address-editing__col custom-col custom-col--50 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit"
                 @click="editTranslate(['common_address'])"></div>
            <DefaultInput
                v-bind:class="{'ui-no-valid': validation.address}"
                :error="validation.address"
                :errorTxt="serverError ? validationTxt.address : $t(`${validationTranslate.address}.localization_value.value`)"
                :label="$t('common_address.localization_value.value')"
                :type="'text'"
                v-model="data.user_personal_contact.address"
            />
          </div>
          <div class="address-editing__col custom-col custom-col--50 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit"
                 @click="editTranslate(['common_city'])"></div>
            <DefaultInput
                    v-bind:class="{'ui-no-valid': validation.city}"
                    :error="validation.city"
                    :errorTxt="serverError ? validationTxt.city : $t(`${validationTranslate.city}.localization_value.value`)"
                    :label="$t('common_city.localization_value.value')"
                    :type="'text'"
                    v-model="data.user_personal_contact.city"
            />
          </div>

          <div class="address-editing__col custom-col custom-col--50 custom-col--xs-100"
               v-if="fromRussia"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit"
                 @click="editTranslate(['common_cityCyrillic'])"></div>
            <DefaultInput
                    v-bind:class="{'ui-no-valid': validation.cityCyrillic}"
                    :error="validation.cityCyrillic"
                    :errorTxt="$t(`${validationTranslate.cityCyrillic}.localization_value.value`)"
                    :label="$t('common_cityCyrillic.localization_value.value')"
                    :type="'text'"
                    v-model="data.user_personal_contact.cyrillic_address"
            />
          </div>

          <div class="address-editing__col custom-col custom-col--50 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit"
                 @click="editTranslate(['common_country'])"></div>
            <DefaultSelect
                    v-bind:class="{'ui-no-valid': validation.selectedCountry}"
                    :options="options"
                    :error="validation.selectedCountry"
                    :errorTxt="serverError ? validationTxt.selectedCountry : $t(`${validationTranslate.selectedCountry}.localization_value.value`)"
                    :label="$t('common_country.localization_value.value')"
                    @change="changeCountry"
                    :selected="selectedCountry"
                    :optionsLabel="'label'"
            />
          </div>

          <div class="address-editing__col custom-col custom-col--50 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit"
                 @click="editTranslate(['common_regions'])"></div>
            <DefaultSelect
                v-bind:class="{'ui-no-valid': validation.stateDistrict}"
                :error="validation.stateDistrict"
                :errorTxt="serverError ? validationTxt.stateDistrict : $t(`${validationTranslate.stateDistrict}.localization_value.value`)"
                :label="$t('common_regions.localization_value.value')"
                :options="optionsDistrict"
                @change="changeDistrict"
                :selected="stateDistrict"
                :optionsLabel="'label'"
            />
          </div>

          <div class="address-editing__col custom-col custom-col--25 custom-col--xs-50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit"
                 @click="editTranslate(['common_postNumber'])"></div>
            <DefaultInput
                v-bind:class="{'ui-no-valid': validation.postNumber}"
                :error="validation.postNumber"
                :errorTxt="serverError ? validationTxt.postNumber : $t(`${validationTranslate.postNumber}.localization_value.value`)"
                :label="$t('common_postNumber.localization_value.value')"
                :type="'text'"
                v-model="data.user_personal_contact.zip"
            />
          </div>

          <div class="address-editing__col custom-col custom-col--50 custom-col--xs-100"
               v-if="fromSNG"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit"
                 @click="editTranslate(['registration_accountItn'])"></div>
            <DefaultInput
                v-bind:class="{'ui-no-valid': validation.itn}"
                :error="validation.itn"
                :errorTxt="serverError ? validationTxt.itn : $t(`${validationTranslate.itn}.localization_value.value`)"
                :label="$t('registration_accountItn.localization_value.value')"
                :type="'text'"
                v-model="data.itn_number"
            />
          </div>
          <div class="address-editing__col custom-col" v-if="!data.user_alternative_contact"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit"
                 @click="editTranslate(['common_alternative_address_button'])"></div>
            <button class="main-button alternative-address-btn" v-on:click="showAdditionalAddressForm = !showAdditionalAddressForm">
              {{ $t('common_alternative_address_button.localization_value.value') }}
            </button>
          </div>
        </div>
        <div v-if="showAdditionalAddressForm || data.user_alternative_contact">
          <div class="section-label"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit"
                 @click="editTranslate(['common_alternative_address', 'common_alternative_address_button'])"></div>
            {{ $t('common_alternative_address.localization_value.value') }}
          </div>

          <div class="address-editing__row custom-row" v-if="Object.keys(data).length > 0">
            <div class="address-editing__col custom-col custom-col--50 custom-col--xs-100"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit"
                   @click="editTranslate(['common_address'])"></div>
              <DefaultInput
                  v-bind:class="{'ui-no-valid': validation.addressAlternative}"
                  :error="validation.addressAlternative"
                  :errorTxt="serverError ? validationTxt.addressAlternative : $t(`${validationTranslate.addressAlternative}.localization_value.value`)"
                  :label="$t('common_address.localization_value.value')"
                  :type="'text'"
                  v-model="data.user_alternative_contact.address"
              />
            </div>
            <div class="address-editing__col custom-col custom-col--50 custom-col--xs-100"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit"
                   @click="editTranslate(['common_city'])"></div>
              <DefaultInput
                  v-bind:class="{'ui-no-valid': validation.cityAlternative}"
                  :error="validation.cityAlternative"
                  :errorTxt="serverError ? validationTxt.cityAlternative : $t(`${validationTranslate.cityAlternative}.localization_value.value`)"
                  :label="$t('common_city.localization_value.value')"
                  :type="'text'"
                  v-model="data.user_alternative_contact.city"
              />
            </div>

            <div class="address-editing__col custom-col custom-col--50 custom-col--xs-100"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit"
                   @click="editTranslate(['common_country'])"></div>
              <DefaultSelect
                  v-bind:class="{'ui-no-valid': validation.selectedAlternativeCountry}"
                  :options="optionsCountryAlternative"
                  :error="validation.selectedAlternativeCountry"
                  :errorTxt="serverError ? validationTxt.selectedAlternativeCountry : $t(`${validationTranslate.selectedAlternativeCountry}.localization_value.value`)"
                  :label="$t('common_country.localization_value.value')"
                  @change="changeAlternativeCountry"
                  :selected="selectedAlternativeCountry"
                  :optionsLabel="'label'"
              />
            </div>

            <div class="address-editing__col custom-col custom-col--50 custom-col--xs-100"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit"
                   @click="editTranslate(['common_regions'])"></div>
              <DefaultSelect
                  v-bind:class="{'ui-no-valid': validation.stateDistrictAlternative}"
                  :error="validation.stateDistrictAlternative"
                  :errorTxt="serverError ? validationTxt.stateDistrictAlternative : $t(`${validationTranslate.stateDistrictAlternative}.localization_value.value`)"
                  :label="$t('common_regions.localization_value.value')"
                  :options="optionsDistrictAlternative"
                  @change="changeAlternativeDistrict"
                  :selected="stateDistrictAlternative"
                  :optionsLabel="'label'"
              />
            </div>

            <div class="address-editing__col custom-col custom-col--25 custom-col--xs-50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit"
                   @click="editTranslate(['common_postNumber'])"></div>
              <DefaultInput
                  v-bind:class="{'ui-no-valid': validation.postNumberAlternative}"
                  :error="validation.postNumberAlternative"
                  :errorTxt="serverError ? validationTxt.postNumberAlternative : $t(`${validationTranslate.postNumberAlternative}.localization_value.value`)"
                  :label="$t('common_postNumber.localization_value.value')"
                  :type="'text'"
                  v-model="data.user_alternative_contact.zip"
              />
            </div>
            <div class="address-editing__col custom-col custom-col--100 custom-col--xs-50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit"
                   @click="editTranslate(['common_use_alternative_address'])"></div>
              <DefaultCheckbox
                  :label="$t('common_use_alternative_address.localization_value.value')"
                  :value="useUserAlternativeAddress"
                  @change.native="useUserAlternativeAddress = !useUserAlternativeAddress"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="address-editing__btn"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit"
             @click="editTranslate(['common_cancel', 'common_update'])"></div>
        <span
           class="site-link site-link--alt address-editing__btn-i"
           @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>

        <MainButton
            :value="$t('common_update.localization_value.value')"
            class="address-editing__btn-i"
            v-on:click.native="submitForm"
            v-bind:class="{'disabled-btn' : $store.getters.getProfileBtn}"
        />
      </div>
    </template>
  </modal>
</template>


<script>
  import Modal from "../../../../commonModals/Modal";
  import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
  import DefaultCheckbox from "../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import DefaultSelect from "../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import {validation} from "../../../../../services/validation";
  import {filterCountries} from "../../../../../services/filterCountries";
  import {checkAccess} from "../../../../../mixins/userCredentionalMixins/userCredentional";
  import {POLAND_COUNTRY_ID, RUSSIA_COUNTRY_ID} from "../../../../../staticData/staticVariables";

  export default {
    name: "AddressEditing",
    components: {
      Modal,
      DefaultInput,
      DefaultCheckbox,
      DefaultSelect,
      MainButton,
    },

    mixins: [checkAccess],

    data() {
      return {
        data: {},
        fromSNG: false,
        fromRussia: false,
        serverError: false,

        showAdditionalAddressForm: false,

        selectedCountry: null,
        stateDistrict: null,
        selectedAlternativeCountry: null,
        stateDistrictAlternative: null,

        useUserAlternativeAddress: 0,

        validation: {
          address: '',
          city: '',
          cityCyrillic: '',
          postNumber: '',
          selectedCountry: '',
          stateDistrict: '',
          addressAlternative: '',
          cityAlternative: '',
          postNumberAlternative: '',
          selectedAlternativeCountry: '',
          stateDistrictAlternative: '',
          useUserAlternativeAddress: '',
          itn: '',
        },

        validationTranslate: {
          address: 'default',
          city: 'default',
          cityCyrillic: 'default',
          postNumber: 'default',
          selectedCountry: 'default',
          stateDistrict: 'default',
          addressAlternative: 'default',
          cityAlternative: 'default',
          cityCyrillicAlternative: 'default',
          postNumberAlternative: 'default',
          selectedAlternativeCountry: 'default',
          stateDistrictAlternative: 'default',
          useUserAlternativeAddress: 'default',
          itn: 'default',
        },

        validationTxt: {
          address: false,
          city: false,
          cityCyrillic: false,
          postNumber: false,
          selectedCountry: false,
          stateDistrict: false,
          addressAlternative: false,
          cityAlternative: false,
          cityCyrillicAlternative: false,
          postNumberAlternative: false,
          selectedAlternativeCountry: false,
          stateDistrictAlternative: false,
          useUserAlternativeAddress: false,
          itn: false,
        },

        options: [],
        optionsCountryAlternative: [],
        optionsDistrict: [],
        optionsDistrictAlternative: [],
      }
    },

    mounted() {
      this.data = this._.cloneDeep(this.$store.getters.getUserProfile);
      this.data.user_personal_contact.zip === null ? this.data.user_personal_contact.zip = '' : false
      this.data.user_personal_contact.address === null ? this.data.user_personal_contact.address = '' : false
      this.data.user_personal_contact.city === null ? this.data.user_personal_contact.city = '' : false
      this.selectedCountry = {
        id: this.data.user_personal_contact.country.id,
        label: this.data.user_personal_contact.country.name,
      }
      this.stateDistrict = {
        id: this.data.user_personal_contact.region.id,
        label :this.data.user_personal_contact.region.name,
      }

      if(!this.data.user_alternative_contact) {
        this.data.user_alternative_contact = {
          zip: null,
          address: null,
          city: null,
        }
      }
      this.data.user_alternative_contact.zip === null ? this.data.user_alternative_contact.zip = '' : false
      this.data.user_alternative_contact.address === null ? this.data.user_alternative_contact.address = '' : false
      this.data.user_alternative_contact.city === null ? this.data.user_alternative_contact.city = '' : false
      if(this.data.user_alternative_contact?.country?.id) {
        this.selectedAlternativeCountry = {
          id: this.data.user_alternative_contact?.country?.id,
          label: this.data.user_alternative_contact?.country?.name,
        }
      }
      if(this.data.user_alternative_contact?.region?.id) {
        this.stateDistrictAlternative = {
          id: this.data.user_alternative_contact?.region?.id,
          label :this.data.user_alternative_contact?.region?.name,
        }
      }

      this.data.itn_number === null ? this.data.itn_number = '' : false

      if(this.data.user_personal_contact?.country_group?.fromSNGGroup){
        this.fromSNG = true
      } else {
        this.fromSNG = false
      }

      this.fromRussia = this.data.user_personal_contact.country.id === RUSSIA_COUNTRY_ID
      this.data.user_personal_contact.cyrillic_address === null ? this.data.user_personal_contact.cyrillic_address = '' : false

      this.$store.dispatch('fetchCountries').then(() => {
        let countries = this._.toArray(this.$store.getters.getCountries)
        let filteredCountries = filterCountries(countries)

        this.options = filteredCountries.countries

        let polandCountry = this._.find(countries, {id: POLAND_COUNTRY_ID})

        this.optionsCountryAlternative = [polandCountry]

        this.$store.dispatch('getRegionsByCountry', this.selectedCountry.id).then(() => {
          this.optionsDistrict = this._.toArray(this.$store.getters.getRegions)
        })

        if(this.selectedAlternativeCountry.id) {
          this.$store.dispatch('getRegionsByCountry', this.selectedAlternativeCountry.id).then(() => {
            this.optionsDistrictAlternative = this._.toArray(this.$store.getters.getRegions)
          })
        }


      })
      this.useUserAlternativeAddress = this.data.use_alternative_address
    },

    methods:{
      changeCountry(val) {
        if(val === '') return this.optionsDistrict = ''
        this.selectedCountry = val;

        if(val?.country_group?.fromSNGGroup){
          this.fromSNG = true
        } else {
          this.fromSNG = false
        }

        this.fromRussia = val.id === RUSSIA_COUNTRY_ID

        this.$store.dispatch('getRegionsByCountry', val.id).then(() => {
          this.optionsDistrict = this._.toArray(this.$store.getters.getRegions);
          this.stateDistrict = ''
        });
      },

      changeAlternativeCountry(val) {
        if(val === '') {
          this.selectedAlternativeCountry = []
          this.optionsDistrictAlternative = []
          return false
        }
        this.selectedAlternativeCountry = val;

        if(val?.country_group?.fromSNGGroup){
          this.fromSNG = true
        } else {
          this.fromSNG = false
        }

        this.fromRussia = val.id === RUSSIA_COUNTRY_ID

        this.$store.dispatch('getRegionsByCountry', val.id).then(() => {
          this.optionsDistrictAlternative = this._.toArray(this.$store.getters.getRegions);
          this.stateDistrictAlternative = ''
        });
      },

      changeDistrict(val) {
        this.stateDistrict = val;
      },

      changeAlternativeDistrict(val) {
        this.stateDistrictAlternative = val;
      },


      submitForm(e) {
        e.preventDefault();

        let validationItems = {
          address: this.data.user_personal_contact.address,
          city: this.data.user_personal_contact.city,
          postNumber: this.data.user_personal_contact.zip,
          selectedCountry: this.selectedCountry,
          stateDistrict: this.stateDistrict,
          addressAlternative: this.data.user_alternative_contact.address,
          cityAlternative: this.data.user_alternative_contact.city,
          postNumberAlternative: this.data.user_alternative_contact.zip,
          selectedAlternativeCountry: this.selectedAlternativeCountry,
          stateDistrictAlternative: this.stateDistrictAlternative,
          useUserAlternativeAddress: this.useUserAlternativeAddress,
          itn: this.data.itn_number,
        }

        let validationOptions = {
          address: {type: ['latin', 'empty',]},
          city: {type: ['latin', 'empty',]},
          selectedCountry: {type: ['empty',]},
          stateDistrict: {type: ['empty',]},
          postNumber: {type: ['empty',]},
          addressAlternative: {type: this.useUserAlternativeAddress ? ['latin', 'empty',] : []},
          cityAlternative: {type: this.useUserAlternativeAddress ? ['latin', 'empty',] : []},
          selectedAlternativeCountry: {type: this.useUserAlternativeAddress ? ['empty',] : []},
          stateDistrictAlternative: {type: this.useUserAlternativeAddress ? ['empty',] : []},
          postNumberAlternative: {type: this.useUserAlternativeAddress ? ['empty',] : []},
          useUserAlternativeAddress: {type: this.useUserAlternativeAddress ? ['empty',] : []},

          itn: {type: this.fromSNG ? ['empty',] : []},
        }

        if(this.data.user_personal_contact.cyrillic_address.length > 0){
          validationItems.cityCyrillic = this.data.user_personal_contact.cyrillic_address ? this.data.user_personal_contact.cyrillic_address : ''
          validationOptions.cityCyrillic = {type: ['onlyCyrillic']}
        }


        let validate = validation(validationItems, validationOptions);
        this.validation = validate.validation;
        this.validationTranslate = validate.validationError

        console.log(validate);
        if(validate.isValidate) {
          this.serverError = false;

          let data = {
            contacts: {
              address: this.data.user_personal_contact.address,
              city: this.data.user_personal_contact.city,
              country_id: this.selectedCountry.id,
              country_region_id: this.stateDistrict.id,
              zip: this.data.user_personal_contact.zip,
              cyrillic_address: this.data.user_personal_contact.cyrillic_address,
            },
            alternative_contacts: {
              address: this.data.user_alternative_contact.address,
              city: this.data.user_alternative_contact.address ? this.data.user_alternative_contact.city : null,
              country_id: this.data.user_alternative_contact.address && this.selectedAlternativeCountry?.id ? this.selectedAlternativeCountry.id : null,
              country_region_id: this.data.user_alternative_contact.address && this.stateDistrictAlternative?.id ? this.stateDistrictAlternative.id : null,
              zip: this.data.user_alternative_contact.address ? this.data.user_alternative_contact.zip : null,
            },
            use_alternative_address: this.useUserAlternativeAddress,
            itn_number: this.data.itn_number,
          }

          this.$store.dispatch('changeProfileAddress', {id: this.data.id, data: data}).then((response) => {

            if(this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE){
              this.$emit('close');
              this.openNotify('success', 'common_notificationRecordChanged')
            } else if(response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
              let errors = response.response.data.errors;

              this.serverError = true;

              errors['contacts.address'] ?           ( this.validation.address = true,          this.validationTxt.address = errors['contacts.address'][0] )  :                 false;
              errors['contacts.city'] ?              ( this.validation.city = true,             this.validationTxt.city = errors['contacts.city'][0] )  :                       false;
              errors['contacts.country_id'] ?        ( this.validation.selectedCountry = true,  this.validationTxt.selectedCountry = errors['contacts.country_id'][0] )  :      false;
              errors['contacts.country_region_id'] ? ( this.validation.stateDistrict = true,    this.validationTxt.stateDistrict = errors['contacts.country_region_id'][0] )  : false;
              errors['contacts.zip'] ?               ( this.validation.postNumber = true,       this.validationTxt.postNumber = errors['contacts.zip'][0] )  :                  false;
            } else if(response.response.status === this.$store.getters.GET_ERRORS.FORBIDDEN_CODE) {
              this.checkAccess2faAt()
            }

          });

        }

      },
    }
  }

</script>

<style lang="scss">
  @import "../../../../../scss/colors";
  @import "../../../../../scss/mixins/mixins";

  .address-editing{

    .modal-component__inner {
      max-width: 740px;
    }

    .modal-component__content{
      background: white;
    }


    &__content{

    }

    &__txt{
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $borderBrown;
      margin-bottom: 30px;
    }

    &__row{
      margin-bottom: -15px;
    }

    &__col{

    }

    &__alert{
      font-size: 12px;
      line-height: 14px;
      color: $black;
    }

    &__btn{
      display: flex;
      align-items: center;
    }

    .alternative-address-btn {
      max-width: max-content;
      font-size: 14px;
    }

    &__btn-i{
      margin-right: 30px;

      @include for-768{
        margin-left: 20px;
      }

      &:last-child{
        margin-right: 0;

        @include for-768{
          margin-left: 0
        }
      }
    }
  }
</style>

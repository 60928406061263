var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"custom-popup-modal custom-popup medium-size",on:{"close":function($event){return _vm.$emit('close')}}},[_c('template',{slot:"header"},[_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([_vm.popupTitle])}}}),_vm._v(" "+_vm._s(_vm.$t(`${_vm.popupTitle}.localization_value.value`))+" ")])]),_c('template',{slot:"body"},[_c('div',{staticClass:"custom-popup__content"},[_c('div',{staticClass:"custom-row"},[_vm._l((_vm.dataItems),function(item,index){return [_c('div',{key:index,staticClass:"custom-col custom-col--md-100",class:{
                'custom-col--50': _vm.dataItems.length > 1 && item.type !== 'radio',
                'd-flex': item.type === 'radio',
                'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()
               }},[((item.type === 'text' && item.field !== 'region') || (!_vm.showRegionsSelect && item.field === 'region' && item.type === 'text'))?[_c('DefaultInput',{class:{'ui-no-valid': item.valid},attrs:{"label":_vm.$t(`${item.name}.localization_value.value`),"error":item.valid,"errorTxt":_vm.$t(`${item.validTxt}.localization_value.value`),"type":item.type,"maxLength":item.maxLength ? item.maxLength : '',"caption":item.maxLength ? _vm.$t('express_maxSymbols.localization_value.value') : '',"autocomplete":_vm._.has(item, 'autocomplete') ? item.autocomplete : true},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})]:_vm._e(),(_vm.showRegionsSelect && item.field === 'region' && _vm.optionsRegions.length > 0)?[_c('DefaultSelect',{class:{'ui-no-valid': item.valid},attrs:{"options":_vm.optionsRegions,"label":_vm.$t(`${item.name}.localization_value.value`),"optionsLabel":'code',"error":item.valid,"errorTxt":_vm.$t(`${item.validTxt}.localization_value.value`),"selected":_vm.getSelectedRegion(item)},on:{"change":val => {_vm.changeRegion(val, index)}}})]:_vm._e(),(item.field === 'country')?[_c('DefaultSelect',{class:{'ui-no-valid': item.valid},attrs:{"options":_vm.options,"label":_vm.$t(`${item.name}.localization_value.value`),"optionsLabel":'label',"error":item.valid,"errorTxt":_vm.$t(`${item.validTxt}.localization_value.value`),"selected":item.value},on:{"change":val => {_vm.changeCountry(val, index)}}})]:_vm._e(),(item.type === 'textarea')?[_c('TextareaDefault',{class:{'ui-no-valid': item.valid},attrs:{"label":_vm.$t(`${item.name}.localization_value.value`),"error":item.valid,"errorTxt":_vm.$t(`${item.validTxt}.localization_value.value`)},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})]:_vm._e()],2),(item.field === 'country')?[_c('div',{key:index+400,staticClass:"custom-col custom-col--md-100",class:{
                'custom-col--50': _vm.dataItems.length > 1 && item.type !== 'radio',
               }})]:_vm._e()]})],2)])]),_c('template',{slot:"footer"},[_c('div',{staticClass:"custom-popup__btn align-items-center d-flex flex-wrap",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['common_cancel','common_save'])}}}),_c('span',{staticClass:"site-link site-link--alt custom-popup__btn-i mr-4 mt-3 mb-3",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t('common_cancel.localization_value.value'))+" ")]),_c('MainButton',{staticClass:"custom-popup__btn-i",attrs:{"value":_vm.$t('common_save.localization_value.value')},nativeOn:{"click":function($event){return _vm.$emit('save', _vm.items)}}})],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
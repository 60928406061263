<template>
  <div class="user-card">

    <div class="user-card__img">
      <div class="user-card__img-i">
        <img v-if="user.userAvatar && user.userAvatar.id"
             :src="`data:${user.userAvatar.mimeType};base64,${user.userAvatar.base64}`" alt="img">
        <img v-else style="background: #ce9e64" src="/img/profile-group/profile-photo-default.svg" alt="img">
      </div>
    </div>
    <div class="user-card__content">
      <div class="user-card__head">
        <div class="user-card__head-info">
          <div class="custom-row">
            <div class="custom-col custom-col--50 mb-0">
              <div class="user-card__head-name" v-if="Object.keys(ex.express.user).length > 0">
                <ValueHelper
                    :value="ex.express.user"
                    :deep="'contacts.0.user_full_name'"
                />
              </div>
            </div>
          </div>

          <div class="user-card__head-row">
            <div class="amount-row light">
              Balance:
              <span class="color-red important font-weight-bold m-0">
                $ {{ex.express.user.balance}}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="user-card__body">
        <div class="block-table">
          <div class="block-table__row mb-0">
            <div class="block-table__col mb-3">
              <TitleHorizontal
                  class="black mb-0"
                  :value="$t('ex_personalInformation.localization_value.value')"
                  :rightBtn="true"
                  :rightBtnType="'edit'"
                  :rightBtnText="'Edit'"
                  @rightBtnClick="changeInformationDataPopup()"
              />
            </div>
            <div class="block-table__col mb-3"
                 v-bind:class="{'ui-no-valid': ex.express.personal.validation.personalName}"
            >
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['express_ContactName'])"></div>
                {{$t('express_ContactName.localization_value.value')}}
              </div>

              <div class="block-table__content">
                <ValueHelper
                    :value="ex.express.personal"
                    :deep="'information.personalName'"
                />
              </div>
              <span class="color-orange-validation mt-2" v-if="ex.express.personal.validation.personalName &&
                $t(`${ex.express.personal.validationTranslate.personalName}.localization_value.value`)">
                  {{$t(`${ex.express.personal.validationTranslate.personalName}.localization_value.value`)}}
                </span>
            </div>
            <div class="block-table__col mb-3"
                 v-bind:class="{'ui-no-valid': ex.express.personal.validation.personalPhone}"
            >
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_phone'])"></div>
                {{$t('common_phone.localization_value.value')}}
              </div>

              <div class="block-table__content">
                <ValueHelper
                    :value="ex.express.personal"
                    :deep="'information.personalPhone'"
                />
              </div>
              <span class="color-orange-validation mt-2" v-if="ex.express.personal.validation.personalPhone &&
                $t(`${ex.express.personal.validationTranslate.personalPhone}.localization_value.value`)">
                  {{$t(`${ex.express.personal.validationTranslate.personalPhone}.localization_value.value`)}}
                </span>
            </div>
            <div class="block-table__col mb-3"
                 v-bind:class="{'ui-no-valid': ex.express.personal.validation.personalEmail}"
            >
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['express_Email'])"></div>
                {{$t('express_Email.localization_value.value')}}
              </div>

              <div class="block-table__content">
                <ValueHelper
                    :value="ex.express.personal"
                    :deep="'information.personalEmail'"
                />
              </div>
              <span class="color-orange-validation mt-2" v-if="ex.express.personal.validation.personalEmail &&
                $t(`${ex.express.personal.validationTranslate.personalEmail}.localization_value.value`)">
                  {{$t(`${ex.express.personal.validationTranslate.personalEmail}.localization_value.value`)}}
                </span>
            </div>

            <div class="block-table__col mb-3">
              <TitleHorizontal
                  class="black mb-0"
                  :value="$t('ex_deliveryAddress.localization_value.value')"
                  :rightBtn="true"
                  :rightBtnType="'edit'"
                  :rightBtnText="'Edit'"
                  @rightBtnClick="changeDeliveryDataPopup()"
              />
            </div>

            <div class="block-table__col block-table__col--50 mb-3"
                 v-bind:class="{'ui-no-valid': ex.express.delivery.validation.address}"
            >
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_address'])"></div>
                {{$t('common_address.localization_value.value')}}
              </div>

              <div class="block-table__content">
                <ValueHelper
                    :value="ex.express.delivery"
                    :deep="'delivery.address'"
                />
              </div>
              <span class="color-orange-validation mt-2" v-if="ex.express.delivery.validation.address &&
                $t(`${ex.express.delivery.validationTranslate.address}.localization_value.value`)">
                  {{$t(`${ex.express.delivery.validationTranslate.address}.localization_value.value`)}}
                </span>
            </div>

            <div class="block-table__col block-table__col--50 mb-3"
                 v-bind:class="{'ui-no-valid': ex.express.delivery.validation.address2}"
            >
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_addressSecond'])"></div>
                {{$t('common_addressSecond.localization_value.value')}}
              </div>

              <div class="block-table__content">
                <ValueHelper
                    :value="ex.express.delivery"
                    :deep="'delivery.address2'"
                />
              </div>
              <span class="color-orange-validation mt-2" v-if="ex.express.delivery.validation.address2 &&
                $t(`${ex.express.delivery.validationTranslate.address2}.localization_value.value`)">
                  {{$t(`${ex.express.delivery.validationTranslate.address2}.localization_value.value`)}}
                </span>
            </div>

            <div class="block-table__col block-table__col--50 mb-3"
                 v-bind:class="{'ui-no-valid': ex.express.delivery.validation.city}"
            >
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_city'])"></div>
                {{$t('common_city.localization_value.value')}}
              </div>

              <div class="block-table__content">
                <ValueHelper
                    :value="ex.express.delivery"
                    :deep="'delivery.city'"
                />
              </div>
              <span class="color-orange-validation mt-2" v-if="ex.express.delivery.validation.city &&
                $t(`${ex.express.delivery.validationTranslate.city}.localization_value.value`)">
                  {{$t(`${ex.express.delivery.validationTranslate.city}.localization_value.value`)}}
                </span>
            </div>

            <div class="block-table__col block-table__col--50 mb-3"
                 v-bind:class="{'ui-no-valid': ex.express.delivery.validation.region}"
            >
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_region'])"></div>
                {{$t('common_region.localization_value.value')}}
              </div>

              <div class="block-table__content">
                <ValueHelper
                    :value="ex.express.delivery"
                    :deep="'delivery.region'"
                />
              </div>
              <span class="color-orange-validation mt-2" v-if="ex.express.delivery.validation.region &&
                $t(`${ex.express.delivery.validationTranslate.region}.localization_value.value`)">
                  {{$t(`${ex.express.delivery.validationTranslate.region}.localization_value.value`)}}
                </span>
            </div>

            <div class="block-table__col block-table__col--50 mb-3"
                 v-bind:class="{'ui-no-valid': ex.express.delivery.validation.country}"
            >
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_country'])"></div>
                {{$t('common_country.localization_value.value')}}
              </div>

              <div class="block-table__content">
                <ValueHelper
                    :value="ex.express.delivery"
                    :deep="'delivery.country.label'"
                />
              </div>
              <span class="color-orange-validation mt-2" v-if="ex.express.delivery.validation.country &&
                $t(`${ex.express.delivery.validationTranslate.country}.localization_value.value`)">
                  {{$t(`${ex.express.delivery.validationTranslate.country}.localization_value.value`)}}
                </span>
              <div class="site-link site-link-alt mt-3 wfc d-flex"
                   v-if="ex.express.delivery.delivery.country !== ''"
                   v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                        'express_ViewCustomLimits',
                      ])"></div>
                <div
                    @click="showCustomLimitsPopup(ex.express.delivery.getCountry().id, 'isModalCustomLimitsPopup')"
                >
                  {{$t('express_ViewCustomLimits.localization_value.value')}}
                </div>
              </div>
            </div>

            <div class="block-table__col block-table__col--50 mb-3"
                 v-bind:class="{'ui-no-valid': ex.express.delivery.validation.zip}"
            >
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_zipCode'])"></div>
                {{$t('common_zipCode.localization_value.value')}}
              </div>

              <div class="block-table__content">
                <ValueHelper
                    :value="ex.express.delivery"
                    :deep="'delivery.zip'"
                />
              </div>
              <span class="color-orange-validation mt-2" v-if="ex.express.delivery.validation.zip &&
                $t(`${ex.express.delivery.validationTranslate.zip}.localization_value.value`)">
                  {{$t(`${ex.express.delivery.validationTranslate.zip}.localization_value.value`)}}
                </span>
            </div>

          </div>

        </div>


      </div>

      <!--<ChangePasswordPopup-->
        <!--v-if="isModalChangePasswordPopup"-->
        <!--@close="changeChangePasswordPopup(false)"-->
      <!--/>-->


      <!--<PhonesPopup-->
          <!--v-if="isModalPhonesPopup"-->
          <!--@close="changePhonesPopup(false)"-->
      <!--/>-->

      <ChangeDataPopup
          v-if="isModalChangeAddressPopup"
          :items="changeDataPopup"
          :popupTitle="popupTitle"
          :getRegionsIfUSA="true"
          :ex="ex"
          @save="saveOrderData"
          @close="isModalChangeAddressPopup = false"
      />


      <CustomLimitsPopup
          @close="isModalCustomLimitsPopup = false"
          v-if="isModalCustomLimitsPopup"
      />
      <!--<HistoryOfChanges-->
          <!--v-if="isModalHistoryOfChanges"-->
          <!--@close="changeHistoryOfChanges(false)"-->
      <!--/>-->

    </div>
  </div>
</template>

<script>
  import TitleHorizontal from "../../../../../../coreComponents/TitleHorizontal/TitleHorizontal";
  import {PersonalInformation} from "../../../../../../globalModels/PersonalInformation";
  import {Delivery} from "../../../../../../globalModels/Delivery";
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
  import {ExpressMixinDeliveryData} from "../../../../../../../mixins/expressMixins/ExpressMixinDeliveryData";
  import ChangeDataPopup from "../../../../popups/ChangeDataPopup/ChangeDataPopup";
  import {customLimitsMixin} from "@/mixins/commonMixins/customLimitsMixin";
  import CustomLimitsPopup from "../../../../../../coreComponents/Popups/CustomLimitsPopup/CustomLimitsPopup";

  export default {
    name: "OrderCard",
    components:{
      CustomLimitsPopup,
      ValueHelper,
      TitleHorizontal,
      ChangeDataPopup,
    },

    mixins: [ExpressMixinDeliveryData, customLimitsMixin],

    props:{
      ex: Object,
      user: Object,
      personal: Object,
      deliveryFBM: Object,
    },


    data(){
      return{
        isModalChangePasswordPopup: false,
        isModalPhonesPopup: false,
        isModalChangeAddressPopup: false,
        isModalHistoryOfChanges: false,
        isModalCustomLimitsPopup: false,

        changeDataPopup: [],

        typeFieldsChanges: null,
        popupTitle: '',


        /**
         * Fake models for check validation on Change Data Popup
         */
        PI: new PersonalInformation(),
        Delivery: new Delivery(),

      }
    },

    mounted() {
      console.log(this.user);
      console.log(555555555555);
    },

    methods: {
      changeChangePasswordPopup(val){
        this.isModalChangePasswordPopup = val
      },

      changePhonesPopup(val){
        this.isModalPhonesPopup= val
      },

      changeChangeDataPopup(val){
        this.isModalChangeAddressPopup = true
        this.changeDataPopup = val
      },

      changeHistoryOfChanges(val){
        this.isModalHistoryOfChanges = val
      },

      changeInformationDataPopup(){

        this.PI.setPersonalName(this.ex.express.personal.information.personalName)
        this.PI.setPersonalPhone(this.ex.express.personal.information.personalPhone)
        this.PI.setPersonalEmail(this.ex.express.personal.information.personalEmail)

        this.setPopupInformationData()
        this.typeFieldsChanges = 'personal'
        this.popupTitle = 'common_personalChanges'
        this.isModalChangeAddressPopup = true
      },

      changeDeliveryDataPopup(){

        this.Delivery.setAddress(this.ex.express.delivery.delivery.address)
        this.Delivery.setAddress2(this.ex.express.delivery.delivery.address2)
        this.Delivery.setCity(this.ex.express.delivery.delivery.city)
        this.Delivery.setRegion(this.ex.express.delivery.delivery.region)
        this.Delivery.setCountry(this.ex.express.delivery.delivery.country)
        this.Delivery.setZip(this.ex.express.delivery.delivery.zip)

        this.setPopupDeliveryData()
        this.typeFieldsChanges = 'delivery'
        this.popupTitle = 'common_deliveryChanges'
        this.isModalChangeAddressPopup = true
      },

      saveOrderData(data) {
        switch (this.typeFieldsChanges) {
          case 'personal':
            this.personalUpdate(data)
            break

          case 'delivery':
            this.deliveryUpdate(data)
            break

        }
      },



    }
  }
</script>

<style lang="scss" scoped>
.ui-no-valid {
  .color-orange-validation {
    font-size: 11px;
    line-height: 15px;
  }

  .block-table__content, .block-table__label {
    color: #F2994A;
  }
}
</style>

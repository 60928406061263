<template>
	<ExpressOrderShowUser
					:ex="ex"
          v-if="finishLoading"
	/>
</template>

<script>
	import ExpressOrderShowUser from "./ExpressOrderShowUser/ExpressOrderShowUser";
	import {proformMixins} from "../../../../../mixins/proformMixins/proformMixins";
	import {Express} from "../../models/Express";
	import {expressMixinsFromCard} from "../../../../../mixins/expressMixins/expressMixin";

	export default {
		name: "ExpressOrderShow",

		components: {
      ExpressOrderShowUser
		},

		mixins: [proformMixins, expressMixinsFromCard],

		watch: {
			downloadPermissions() {
				this.checkPermission()
			},
		},

		data() {
			return {
				ex: new Express(),
        finishLoading: false,
			}
		},


		created() {

			if(this.downloadPermissions){
				if(!this.checkPermission()) return
			}

			this.ex.setType(this.$route.params.type)

			// initialize first product
			this.initializeProductProformArray(this.ex.express.products).then(() => {
				this.$store.dispatch('getExpress', this.$route.params.id).then(() => {

					this.ex.setExpressItem(this.$store.getters.getExpressItem, this.isAdmin)

          this.finishLoading = true
				}).catch(error => this.createErrorLog(error))
			}).catch(error => this.createErrorLog(error))
		},

		methods: {

		},

	}
</script>

<style scoped>

</style>

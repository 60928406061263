
export const customLimitsMixin = {
  methods: {
    showCustomLimitsPopup(id, isModalCustomLimitsPopup) {
      let filter = `?filter[DeliveryCountryCustomCountryId]=${id}`

      this.$store.dispatch('getCustomLimits', filter).then(() => {
        this[isModalCustomLimitsPopup] = true
      })
    },
  }
}

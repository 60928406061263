<template>
  <div class="order-type-card">
    <img class="order-type-card__ico"
         v-if="srcImg"
         alt="ico"
         :src="srcImg"
    >
    <div class="order-type-card__text" v-else-if="text">
      {{text}}
    </div>
  </div>
</template>

<script>
  export default {
    name: "OrderTypeCardBig",

    props: {
      srcImg: {
        type: String,
        default: null,
      },
      text: {
        type: String,
        default: null,
      },
    }
  }
</script>

<style lang="scss">
  @import "../../../scss/colors";
  @import "../../../scss/mixins/mixins";

  .order-type-card{


    display: flex;
    height: 124px;
    justify-content: center;
    align-items: center;
    border: 2px solid $accent;
    box-sizing: border-box;
    border-radius: 5px;
    transition: .15s;
    padding: 30px;
    position: relative;

    @include for-1120{
      height: 100px;
      padding: 15px;
    }

    &:after{
      content: '';
      right: 8px;
      top: 8px;
      width: 16px;
      height: 16px;
      background-image: url("../../../assets/img/UI-group/white-brown-check-icon.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      opacity: 0;
      transition: .15s;
      position: absolute;
    }

    &:hover{
      background-color: $brown;
      border-color: $brown;

      .order-type-card__ico {
        filter: brightness(0) grayscale(1) invert(1);
      }

      .order-type-card__text {
        filter: brightness(0) grayscale(1) invert(1);
      }

      &:after{
        opacity: 1;
        visibility: visible;
      }
    }


    &__ico{
      /*max-width: 100%;*/
      max-height: 100%;
      position: relative;
      z-index: 2;
      transition: .15s ease;

      max-width: 180px;
    }

    &__text{
      /*max-width: 100%;*/
      max-height: 100%;
      position: relative;
      z-index: 2;
      transition: .15s ease;
      font-size: 24px;

      max-width: 180px;
    }
  }

</style>

import { render, staticRenderFns } from "./ProductsDetailsWrap.vue?vue&type=template&id=27622f1c&scoped=true"
import script from "./ProductsDetailsWrap.vue?vue&type=script&lang=js"
export * from "./ProductsDetailsWrap.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27622f1c",
  null
  
)

export default component.exports
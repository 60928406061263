
export const scaleMixins = {

  methods: {
    changeWeightScaleMixin(device) {
      device.addEventListener("inputreport", (event) => {
        const { data, device } = event;
        let buffArray = new Uint8Array(data.buffer);
        // console.log(buffArray);

        this.productId = device?.productId
        this.deviceDataArr = buffArray

        let result = this.getScaleDecodeData(buffArray)

        this.weightScaleLB = result.weightScaleFirst
        this.weightScaleOZ = result.weightScaleSecond
      })
    },

    getScaleDecodeData(buffArray) {
      let weightScaleFirst = ''
      let weightScaleSecond = ''

      const lb = 12
      const oz = 11
      const kg = 3
      const gr = 2

      // 0 - status 2-(0) / 4-(accept weight) / 3-(pending scale)
      // 1 - lb(12) / oz(11) / kg(3)
      // 2 - 255
      // 3 - weight (max 255 oz) =>
      // 4 - 256 = [4] = 1

      // EXAMPLE
      // raw_weight = data[4] + data[5] * 256
      //
      // if data[2] == 11:
      // ounces = raw_weight * 0.1
      // weight = ounces
      // elif data[2] == 2:
      // grams = raw_weight
      // weight = grams * .035274
      //
      // pounds, ounces = divmod(weight, 16)

      if(buffArray[0] === 4) {
        // console.log(buffArray);
        // console.log(buffArray[3]);

        switch (buffArray[1]) {
          case lb :
            weightScaleFirst = buffArray[3] / 10 + ''
            weightScaleSecond = ''
            break
          case oz :
            weightScaleFirst = ''
            weightScaleSecond = (buffArray[3] + buffArray[4] * 256) / 10
            break
          case kg :
            weightScaleFirst = buffArray[3] / 10 + ''
            weightScaleSecond = ''
            break
          case gr :
            weightScaleFirst = ''
            weightScaleSecond = (buffArray[3] + buffArray[4] * 256)
            break
          default :
            weightScaleFirst = buffArray[3] / 10 + ''
            // this.dimensions.data.weightLB = ''
            // this.dimensions.data.weightOZ = ''
            break
        }


      } else if(buffArray[0] === 2){
        weightScaleFirst = 0
        weightScaleSecond = 0
      }


      return {weightScaleFirst, weightScaleSecond}
    },
  },

}
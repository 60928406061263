var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fragment"},[_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['fbm_addWeightScale'])}}}),(_vm.canUseScale && !_vm.foundDevice)?_c('MainButton',{staticClass:"mb-3 wfc",attrs:{"value":_vm.$t('fbm_addWeightScale.localization_value.value')},nativeOn:{"click":function($event){return _vm.addWeightScaleDevice.apply(null, arguments)}}}):_vm._e()],1),_c('div',{staticClass:"order-create__row custom-row"},[_c('div',{staticClass:"order-create__col custom-col custom-col--66 custom-col--md-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'packaging_NamePackage',
                      ])}}}),_c('SearchSelect',{class:{'ui-no-valid': _vm.packaging.validation.name || _vm.packNameError},attrs:{"options":_vm.$store.getters.getProfilesPackaging,"label":_vm.$t('packaging_NamePackage.localization_value.value'),"errorTxt":_vm.serverError ? _vm.packaging.validationTxt.name : _vm.$t(`${_vm.packaging.validationTranslate.name}.localization_value.value`),"error":_vm.packaging.validation.name,"selected":_vm.packaging.packaging.name,"optionsLabel":'package_name',"typeSelect":'packaging',"functionSearch":_vm.functionSearchPackaging,"disabled":_vm.allDisabled},on:{"change":_vm.changePackaging}}),(_vm.packNameError)?_c('div',{staticClass:"color-orange-validation mt-2"},[_vm._v(" "+_vm._s(_vm.$t(`error_required.localization_value.value`))+" ")]):_vm._e()],1),_c('div',{staticClass:"order-create__col custom-col custom-col--33 mb-0 custom-col--md-100"}),_c('div',{staticClass:"order-create__col custom-col custom-col--16 custom-col--md-25 custom-col--sm-50",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'packaging_Weight',
                      ])}}}),_c('DefaultInput',{class:{'ui-no-valid': _vm.packaging.validation.weight},attrs:{"label":_vm.$t('packaging_Weight.localization_value.value'),"type":'text',"placeholder":'e.g. 10',"errorTxt":_vm.serverError ? _vm.packaging.validationTxt.weight : _vm.$t(`${_vm.packaging.validationTranslate.weight}.localization_value.value`),"error":_vm.packaging.validation.weight,"disabled":_vm.allDisabled},nativeOn:{"input":function($event){return _vm.changePackagingValue($event, true)}},model:{value:(_vm.packaging.packaging.weight),callback:function ($$v) {_vm.$set(_vm.packaging.packaging, "weight", $$v)},expression:"packaging.packaging.weight"}})],1),_c('div',{staticClass:"order-create__col custom-col custom-col--16 custom-col--md-25 custom-col--sm-50",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'packaging_Height',
                      ])}}}),_c('DefaultInput',{class:{'ui-no-valid': _vm.packaging.validation.height},attrs:{"label":_vm.$t('packaging_Height.localization_value.value'),"type":'text',"placeholder":'e.g. 10',"errorTxt":_vm.serverError ? _vm.packaging.validationTxt.height : _vm.$t(`${_vm.packaging.validationTranslate.height}.localization_value.value`),"error":_vm.packaging.validation.height,"disabled":_vm.allDisabled},on:{"input":_vm.changePackagingValue},model:{value:(_vm.packaging.packaging.height),callback:function ($$v) {_vm.$set(_vm.packaging.packaging, "height", $$v)},expression:"packaging.packaging.height"}})],1),_c('div',{staticClass:"order-create__col custom-col custom-col--16 custom-col--md-25 custom-col--sm-50",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'packaging_Width',
                      ])}}}),_c('DefaultInput',{class:{'ui-no-valid': _vm.packaging.validation.width},attrs:{"label":_vm.$t('packaging_Width.localization_value.value'),"type":'text',"placeholder":'e.g. 10',"errorTxt":_vm.serverError ? _vm.packaging.validationTxt.width : _vm.$t(`${_vm.packaging.validationTranslate.width}.localization_value.value`),"error":_vm.packaging.validation.width,"disabled":_vm.allDisabled},on:{"input":_vm.changePackagingValue},model:{value:(_vm.packaging.packaging.width),callback:function ($$v) {_vm.$set(_vm.packaging.packaging, "width", $$v)},expression:"packaging.packaging.width"}})],1),_c('div',{staticClass:"order-create__col custom-col custom-col--16 custom-col--md-25 custom-col--sm-50",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'packaging_Length',
                      ])}}}),_c('DefaultInput',{class:{'ui-no-valid': _vm.packaging.validation['packageLength']},attrs:{"label":_vm.$t('packaging_Length.localization_value.value'),"type":'text',"placeholder":'e.g. 10',"errorTxt":_vm.serverError ? _vm.packaging.validationTxt['packageLength'] : _vm.$t(`${_vm.packaging.validationTranslate['packageLength']}.localization_value.value`),"error":_vm.packaging.validation['packageLength'],"disabled":_vm.allDisabled},on:{"input":_vm.changePackagingValue},model:{value:(_vm.packaging.packaging['length']),callback:function ($$v) {_vm.$set(_vm.packaging.packaging, 'length', $$v)},expression:"packaging.packaging['length']"}})],1),_c('div',{staticClass:"order-create__col custom-col custom-col--16 custom-col--md-25 custom-col--sm-50 position-relative",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'express_Volume',
                      ])}}}),_c('DefaultInput',{class:{'ui-no-valid': _vm.packaging.validation.volumeWeight},attrs:{"label":_vm.$t('express_Volume.localization_value.value'),"type":'text',"placeholder":'',"errorTxt":_vm.serverError ? _vm.packaging.validationTxt.volumeWeight : _vm.$t(`${_vm.packaging.validationTranslate.volumeWeight}.localization_value.value`),"error":_vm.packaging.validation.volumeWeight,"disabled":true},model:{value:(_vm.packaging.packaging.volumeWeight),callback:function ($$v) {_vm.$set(_vm.packaging.packaging, "volumeWeight", $$v)},expression:"packaging.packaging.volumeWeight"}}),(_vm.$store.getters.getIsAdminRights === 'user' &&
               _vm.showAddProfile)?_c('div',{staticClass:"order-create__product-add-to-profile",on:{"click":_vm.addToProfile}},[[_c('v-popover',{staticClass:"site-tooltip",attrs:{"offset":"5","placement":"top","trigger":"hover"}},[_c('span',[_c('AddToProfileIco')],1),_c('template',{slot:"popover"},[_vm._v(" "+_vm._s(_vm.$t('common_AddProfile.localization_value.value'))+" ")])],2)]],2):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
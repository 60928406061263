<template>
	<modal
			@close="$emit('close')"
			class="custom-popup-modal custom-popup small-size"
	>
		<template slot="header">
			<div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
				<div class="admin-edit" @click="editTranslate(['express_BookingReference',])"></div>
        {{$t('express_fedexPacking.localization_value.value')}}
			</div>
		</template>
		<template slot="body">
			<div class="custom-popup__content d-flex align-items-center">
			  {{$t(`${_.find(FEDEX_PACKAGING_TYPES, {value: type}).translate}.localization_value.value`)}}
			</div>
		</template>
	</modal>
</template>

<script>
	import Modal from '../../../../commonModals/Modal.vue'
  import {FEDEX_PACKAGING_TYPES} from "@/staticData/staticVariables";

	export default {
		name: "NotificationPackingTypePopup",

		components: {
			Modal,
		},

		props: {
      type: String,
		},

		data(){
			return {
        FEDEX_PACKAGING_TYPES: FEDEX_PACKAGING_TYPES,
			}
		},

		mounted() {

		},

		methods: {
			copy() {
				this.copyLink(this.referenceNumber)
				this.openNotify('success', 'common__notificationCopied')
			}
		}

	}

</script>

<style lang="scss">

</style>

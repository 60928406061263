
import {internalOrdersMixin} from "./internalOrdersMixin";
import {CDEK} from "../../components/modules/CDEKModule/models/CDEK";
import {CDEK_LABEL_FILES_TYPES} from "../../staticData/staticVariables";



export const cdekOrdersMixins = {

  mixins: [internalOrdersMixin],

  methods: {

    changeUser(user) {
      this.cdek.setCDEKUser(user)
    },

    changePayOrderPopup(val) {
      this.payOrderPopup = val
    },

    createLabel(id, fromTable = false) {
      this.$store.dispatch('createCDEKLabel', id).then(response => {
        if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

          this.openNotify('success', 'common_notificationRecordCreated')

          if(fromTable) {
            this.$emit('reload')
            if(this.payOrderPopup) this.payOrderPopup = false
            return
          }

          if (this.isAdmin){
            this.$router.push(this.$store.getters.GET_PATHS.cdek)
            return
          }

          this.$router.push(this.$store.getters.GET_PATHS.expressOrders)

        } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
          let errors = response.response.data.errors;

          this.notifyErrorHelper(errors)

        } else {
          this.openNotify('error', 'common_notificationUndefinedError')
        }
      })
    },

    getDefaultWarehouse(val) {

      // get Warehouse for Ship to warehouse field
      let warehouseCodeId = val

      const query = new this.Query();
      let url = '?';
      let myQuery = query
        .for('posts')

      myQuery.where('warehouseCode', warehouseCodeId)

      url = url + myQuery.url().split('?')[1]
      this.$store.dispatch('fetchWarehouses', url).then(() => {
        if(this.$store.getters.getWarehouses.length > 0) {
          this.CDEK.setShippingWarehouse(this.$store.getters.getWarehouses[0])
        }
      })
    },

    getDataExpress(id) {
      return this.$store.dispatch('getExpress', id).then(() => {
        // let express = this.$store.getters.getExpressItem

        // console.log('express: ', express);

        // if(this._.has(express, 'expressOrderInstance')){
        //   this.CDEK.data.packaging.setPackagingItem(express.expressOrderInstance.proform_packaging)
        //   this.CDEK.setProducts(express.proformData)
        //
        //   // if(express.expressOrderInstance.warehouse){
        //   //   this.CDEK.setShippingWarehouse(express.expressOrderInstance.warehouse)
        //   // } else {
        //   //   this.getDefaultWarehouse()
        //   // }
        //
        // }
      })
    },

    getDeliveryPrice(data = false) {
      if(!data)
        data = this.getDataCDEKDeliveryPrice()

      this.$store.dispatch('getCDEKDeliveryPrice', data).then(response => {
        if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
          let data = this.getRespData(response)

          if (data.errors){
            this.openNotify('error', {txtServer: data.errors[0]["message"]})
            return
          }

          this.orderPrice = data["delivery_amount_USD"]
          this.changePayOrderPopup(true)
        } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
          let errors = response.response.data.errors;

          if(Object.keys(errors).length > 0){
            this.openNotify('error', {txtServer: this._.first(errors[Object.keys(errors)[0]])})
          }

          console.log(errors);
        } else {
          this.openNotify('error', 'common_notificationUndefinedError')
        }
      })


    },

    getDataCDEKDeliveryPrice(){
      if(!this.CDEK.firstValidation()) return

      let prepareData = this.CDEK.prepareData()

      let data = {
        'service_type' : prepareData["service_type"],
        'from_location' : parseInt(prepareData["sender_city_ref"]),
        'to_location' : parseInt(prepareData["recipient_city_ref"]),
        'weight' : parseFloat(prepareData["weight"]),
        'length' : parseFloat(prepareData["length"]),
        'width' : parseFloat(prepareData["width"]),
        'height' : parseFloat(prepareData["height"]),
      }

      return data
    },

    save(draft, edit){
      if(!this.CDEK.firstValidation()) return

      // if(this.isAdmin && !this.CDEK.firstValidation()) {
      //   return
      // }

      let data = this.CDEK.prepareData()

      if(edit){
        data = {
          data: this.CDEK.prepareData(),
          id: this.CDEK.getId()
        }
      }

      if(draft){
        let confirm = () => {
          this.saveOrderInternal(draft, edit, data, 'cdek').then(status => {
            this.successSave(status)
          })

          this.$store.commit('setSaveInDraftsPopup', false)
          return true
        }

        this.saveInDraftsFunc(confirm)
      } else {
        this.saveOrderInternal(draft, edit, data, 'cdek').then(response => {
          this.createLabel(response.id)

          return true
        })
      }
    },

    successSave(status) {
      if(!status) return

      // if(this.isCurrentUserRoleUser){
      //   this.$router.push(this.$store.getters.GET_PATHS.expressOrders)
      //   return
      // }

      this.$router.push(this.$store.getters.GET_PATHS.expressOrders)
    },

  },


}


export const cdekOrdersTableMixins = {

  mixins: [cdekOrdersMixins],

  methods: {
    getDeliveryPriceCDEKFromTable(id) {
      let cdek = new CDEK()


      this.$store.dispatch('getOrdersInternal', id).then(() => {



        cdek.setItem(this.$store.getters.getOrdersInternalItem.orderInternal)

        let prepareData = cdek.prepareData()

        let data = {
          'service_type' : prepareData["service_type"],
          'from_location' : parseInt(prepareData["sender_city_ref"]),
          'to_location' : parseInt(prepareData["recipient_city_ref"]),
          'weight' : parseFloat(prepareData["weight"]),
          'length' : parseInt(prepareData["length"]),
          'width' : parseInt(prepareData["width"]),
          'height' : parseInt(prepareData["height"]),
        }

        this.getDeliveryPrice(data)
      }).catch(error => this.createErrorLog(error))
    },




    // openPoshtaLink(link){
    //   let pdfWindow = window.open("label")
    //   pdfWindow.document.write("<style type='text/css'>body {margin: 0; overflow: hidden}</style> <iframe width='100%' height='100%' src='"+ link +"'></iframe>")
    // },

  }
}



export const cdekGetLabelMixin = {
  methods:{
    getCDEKOrderLabel(id) {
      this.$store.dispatch('getCDEKOrderLabel', id).then((response) => {
        // let data = this.getRespData(response)
        this.globalOpenPdfBase64(response["fileContentBase64"])
      })
    },
    getCDEKProductLabel(id) {
      this.$store.dispatch('getCDEKProductLabel', id).then((response) => {
        // let data = this.getRespData(response)
        this.globalOpenPdfBase64(response["fileContentBase64"])
      })
    },

    getCDEKWaybillUUID(filesArr){
      return this._.find(filesArr, {type: CDEK_LABEL_FILES_TYPES.labelFile})?.uuid
    },
    getBarcodeUUID(filesArr){
      return this._.find(filesArr, {type: CDEK_LABEL_FILES_TYPES.markingFile})?.uuid
    },
  }
}

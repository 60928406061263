<template>
  <modal
          @close="$emit('close')"
          class="calling-courier-modal calling-courier"
  >
    <template slot="header">
      <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['express_cancelCourier'])"></div>
        {{$t(`common_CancelCourier.localization_value.value`)}}
      </div>
    </template>
    <template slot="body">
      <div class="calling-courier__content">
        <div class="calling-courier__row custom-row">
          <div class="calling-courier__col custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
              'common_comment',
            ])"></div>
            <TextareaDefault
                    :label="$t('common_comment.localization_value.value')"
                    v-model="txt"
                    :error="validation.txt"
                    :errorTxt="$t(`${validationTranslate.txt}.localization_value.value`)"
                    :type="'text'"
                    v-bind:class="{'ui-no-valid': validation.txt}"
            />
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="calling-courier__btn"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
              'common_cancel',
              'common_submit',
            ])"></div>
        <span
                class="site-link site-link--alt calling-courier__btn-i"
                @click="$emit('close')"
        >
          {{ $t('common_cancel.localization_value.value') }}
        </span>

        <MainButton
                :value="$t('common_submit.localization_value.value')"
                class="calling-courier__btn-i"
                v-on:click.native="submitForm"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
  import TextareaDefault from '../../../../UI/textarea/TextareaDefault/TextareaDefault.vue'
  import {validation} from "../../../../../services/validation";

  export default {
    name: "CallingCourierPopup",
    components: {
      Modal,
      MainButton,
      TextareaDefault,
    },

    props: {
      orderPopupData: Object,
      id: [Number, String],
    },

    data() {
      return {
        txt: '',

        validation: {
          txt: false,
        },

        validationTranslate: {
          txt: '',
        },

        validationTxt: {
          txt: false,
        }
      }
    },



    mounted() {

    },

    methods: {

      submitForm() {
        let validationItems = {
          txt: this.txt,
        }

        let validationOptions = {
          txt: {type: ['empty',]}
        }

        let validate = validation(validationItems, validationOptions)
        this.validation = validate.validation
        this.validationTranslate = validate.validationError

        if(validate.isValidate){
          let data = {
            'order_express_id': this.id,
            'reason': this.txt,
          }


          this.$store.dispatch('cancelCourier', {data: data, type: this.orderPopupData.deliveryTypeName}).then((response) => {

            if(!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')

            switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
                this.openNotify('success', 'common_notificationRecordChanged')
                this.$emit('reload')
                this.$emit('close')
                break
              }
              /**
               * Validation Error
               */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
              /**
               * Undefined Error
               */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          });

        }

      },


    }
  }

</script>

<style lang="scss">
  @import "CancelCourierPopup";
</style>

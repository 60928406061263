<template>
  <AccordBlock>
    <template slot="header">
      <div
          class="section-header--with-button"
          v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
                        'ex_personalInformation',
                        'common_warehouseMN',
                      ])"></div>
        {{$t('ex_personalInformation.localization_value.value')}}

        <MainButton
            v-if="ex.express.warehouse && !(ex.express.warehouse.id === CITY_KIEV.id && ex.express.type === FEDEX_DELIVERY_SERVICE.id)"
            class="ml-3 btn-fit-content"
            :value="$t('common_warehouseMN.localization_value.value')"
            @click.native="openGetMNContactDataPopup"
            v-bind:class="{'disabled-btn' : $store.getters.getLoadingWarehouses}"
        />
      </div>

    </template>
    <template slot="body">
      <DeliveryDetails
          :ex="ex"
          :isEdit="isEdit"
          :vatNumberValidate="vatNumberValidate"
          :isAdvanced="true"
      />
    </template>

  </AccordBlock>
</template>

<script>
import AccordBlock from "@/components/coreComponents/AccordBlock/AccordBlock";
import MainButton from "@/components/UI/buttons/MainButton/MainButton";
import {expressMixinDeliveryDetails} from "@/mixins/expressMixins/expressMixin";
import DeliveryDetails
  from "@/components/modules/ExpressOrdersModule/components/chunks/UserBlocks/DeliveryDetails/DeliveryDetails";
import {CITY_KIEV, FEDEX_DELIVERY_SERVICE} from "@/staticData/staticVariables";

export default {
  name: "DeliveryDetailsWrap",
  components: {DeliveryDetails, MainButton, AccordBlock},

  mixins: [expressMixinDeliveryDetails,],

  props: {
    ex: Object,
    vatNumberValidate: Boolean,
    serverError: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      CITY_KIEV: CITY_KIEV,
      FEDEX_DELIVERY_SERVICE: FEDEX_DELIVERY_SERVICE,
    }
  }

}
</script>

<style scoped>

</style>
<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['proform_Naming'])"></span>
        <DefaultInput
            :label="$t('express_order.localization_value.value')"
            :type="'text'"
            v-model="id"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['requestReturn_Date'])"></span>
        <DatePickerDefault
                :label="$t('requestReturn_Date.localization_value.value')"
                v-model="date"
        >
          <template slot="body">
            <date-picker
                    v-model="date"
                    ref="datePicker"
                    valueType="format"
                    range
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    :format="'MM/DD/YY'"
                    :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['express_DeliveryDate'])"></span>
        <DatePickerDefault
                :label="$t('express_DeliveryDate.localization_value.value')"
                v-model="deliveryDate"
        >
          <template slot="body">
            <date-picker
                    v-model="deliveryDate"
                    ref="datePicker"
                    valueType="format"
                    range
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    :format="'MM/DD/YY'"
                    :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>
      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['shop_user'])"></span>
        <SearchSelect
                :options="options"
                :label="$t('shop_user.localization_value.value')"
                :optionsLabel="'email.contacts[0]'"
                :functionSearch="functionSearchUserFilter"
                @change="changeUsersFilter"
                :selected="userName"
                :typeSelect="'users'"
                :userEmail="true"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['express_TrackingNumber'])"></span>
        <DefaultInput
                :label="$t('express_TrackingNumber.localization_value.value')"
                :type="'text'"
                v-model="trackingNumber"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['express_TrackingNumber'])"></span>
        <DefaultInput
                :label="$t(`${((isBelarus || isSNGGroup) && !isAdmin) ? 'express_TrackingNumberCDEK' : 'express_TrackingNumberNP'}.localization_value.value`)"
                :type="'text'"
                v-model="trackingNumberNPoshta"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['express_Country'])"></span>
        <!--<DefaultInput-->
                <!--:label="$t('express_Country.localization_value.value')"-->
                <!--:type="'text'"-->
                <!--v-model="country"-->
        <!--/>-->

        <DefaultSelect
                :options="optionsCountries"
                :label="$t('common_country.localization_value.value')"
                :selected="getCountrySelected()"
                :optionsLabel="'label'"
                @change="changeCountry"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['express_ItemName'])"></span>
        <DefaultInput
                :label="$t('express_ItemName.localization_value.value')"
            :type="'text'"
            v-model="itemName"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['express_ContactName'])"></span>
        <DefaultInput
                :label="$t('express_ContactName.localization_value.value')"
            :type="'text'"
            v-model="contactName"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['express_Email'])"></span>
        <DefaultInput
                :label="$t('express_Email.localization_value.value')"
            :type="'text'"
            v-model="email"
        />
      </div>
      <!--<div class="table-filter__item"-->
           <!--v-if="navTabs.all.active || navTabs[TNT_DELIVERY_SERVICE.name].active"-->
           <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
        <!--<span class="admin-edit" @click="editTranslate(['express_RefetenceNumber'])"></span>-->
        <!--<DefaultInput-->
                <!--:label="$t('express_RefetenceNumber.localization_value.value')"-->
            <!--:type="'text'"-->
            <!--v-model="refetence"-->
        <!--/>-->
      <!--</div>-->
      <!--<div class="table-filter__item"-->
           <!--v-if="navTabs.all.active || navTabs[TNT_DELIVERY_SERVICE.name].active"-->
           <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
        <!--<span class="admin-edit" @click="editTranslate(['express_BoockingREF'])"></span>-->
        <!--<DefaultInput-->
                <!--:label="$t('express_BoockingREF.localization_value.value')"-->
            <!--:type="'text'"-->
            <!--v-model="boocking"-->
        <!--/>-->
      <!--</div>-->
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['express_PayPall'])"></span>
        <DefaultInput
                :label="$t('express_PayPall.localization_value.value')"
            :type="'text'"
            v-model="transactionNumber"
        />
      </div>
      <div class="table-filter__item"
           v-if="$store.getters.GET_COMMON_AUTHORIZED && $store.getters.GET_COMMON_AUTHORIZED.user && $store.getters.GET_COMMON_AUTHORIZED.user.email
    && $store.getters.GET_COMMON_AUTHORIZED.user.email === 'admin@expressposhta.com'"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['express_expressTrack'])"></span>
        <DefaultInput
            :label="$t('express_expressTrack.localization_value.value')"
            :type="'text'"
            v-model="expressTrack"
        />
      </div>
      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
            'express_ETDSendStatus',
            'express_success',
            'express_error',
            ])"></span>
        <DefaultSelect
            :options="EXPRESS_SEND_STATUSES_ARRAY"
            :label="$t('express_ETDSendStatus.localization_value.value')"
            :selected="getStatusSelected()"
            :optionsLabel="'value'"
            :otherValue="'translation'"
            @change="changeStatus"
        />
      </div>

      <!--<div class="table-filter__item"-->
           <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
        <!--<span class="admin-edit" @click="editTranslate(['proform_Naming'])"></span>-->
        <!--<DefaultInput-->
            <!--:label="'Payment status'"-->
            <!--:type="'text'"-->
            <!--v-model="paymentStatus"-->
        <!--/>-->
      <!--</div>-->
      <!--<div class="table-filter__item"-->
           <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
        <!--<span class="admin-edit" @click="editTranslate(['proform_Naming'])"></span>-->
        <!--<DefaultInput-->
            <!--:label="'Country'"-->
            <!--:type="'text'"-->
            <!--v-model="countryId"-->
        <!--/>-->
      <!--</div>-->
    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import DatePicker from 'vue2-datepicker'
  import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import SearchSelect from "../../../../../../UI/selectiones/SearchSelect/SearchSelect";
  import {TNT_DELIVERY_SERVICE, EXPRESS_SEND_STATUSES_ARRAY} from "../../../../../../../staticData/staticVariables";
  import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";
  import {filterCountriesUSA} from "../../../../../../../services/filterCountries";
  import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";

  export default {
    name: "ExpressOrderTableUserFilter",
    components: {
      DefaultSelect,
      SearchSelect,
      DatePicker,
      DatePickerDefault,
      FilterBlock,
      MainButton,
      DefaultInput,
    },

    mixins: [filterFunc, usersFunc],

    props: {
      navTabs: Object,
      showFilter: Boolean,
      filterGetParams: Object,
      selectHandmade: Array,
    },

    mounted() {
      setTimeout(() => {
        this.$store.dispatch('fetchCountries').then(() => {
          let countries = this._.toArray(this.$store.getters.getCountries)
          filterCountriesUSA(countries)
          this.optionsCountries = countries;
          this.getCountrySelected()
          this.getStatusSelected()
        })
      }, 3000)
    },

    data(){
      return {
        TNT_DELIVERY_SERVICE: TNT_DELIVERY_SERVICE,
        EXPRESS_SEND_STATUSES_ARRAY: EXPRESS_SEND_STATUSES_ARRAY,

        express: '',
        date2: '',
        country: '',
        item: '',
        refetence: '',
        boocking: '',
        paypall: '',

        date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY'): '',
        deliveryDate: this.filterGetParams.deliveryDate ? this.generateFilterDate(this.filterGetParams.deliveryDate, 'MM/DD/YY'): '',
        id: this.filterGetParams.id ? this.filterGetParams.id : '',
        contactName: this.filterGetParams.contactName ? this.filterGetParams.contactName : '',
        email: this.filterGetParams.email ? this.filterGetParams.email : '',
        itemName: this.filterGetParams.itemName ? this.filterGetParams.itemName : '',
        trackingNumber: this.filterGetParams.trackingNumber ? this.filterGetParams.trackingNumber : '',
        expressTrack: this.filterGetParams.expressTrack ? this.filterGetParams.expressTrack : '',
        transactionNumber: this.filterGetParams.transactionNumber ? this.filterGetParams.transactionNumber : '',
        trackingNumberNPoshta: this.filterGetParams.trackingNumberNPoshta ? this.filterGetParams.trackingNumberNPoshta : '',
        paymentStatus: this.filterGetParams.paymentStatus ? this.filterGetParams.paymentStatus : '',
        countryId: this.filterGetParams.countryId ? this.filterGetParams.countryId : '',

        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',
        sendStatus: this.filterGetParams.userId ? this.filterGetParams.sendStatus : '',

        options: [],
        optionsCountries: [],

        filterCounts: [
          'date',
          'deliveryDate',
          'id',
          'contactName',
          'email',
          'itemName',
          'trackingNumber',
          'expressTrack',
          'transactionNumber',
          'trackingNumberNPoshta',
          'paymentStatus',
          'countryId',

          'userName',
          'userId',
          'sendStatus',
        ],
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''
        this.deliveryDate = newVal.deliveryDate ? this.generateFilterDate(newVal.deliveryDate, 'MM/DD/YY') : ''
        this.id = newVal.id ? newVal.id : ''
        this.contactName = newVal.contactName ? newVal.contactName : ''
        this.email = newVal.email ? newVal.email : ''
        this.itemName = newVal.itemName ? newVal.itemName : ''
        this.trackingNumber = newVal.trackingNumber ? newVal.trackingNumber : ''
        this.expressTrack = newVal.expressTrack ? newVal.expressTrack : ''
        this.transactionNumber = newVal.transactionNumber ? newVal.transactionNumber : ''
        this.trackingNumberNPoshta = newVal.trackingNumberNPoshta ? newVal.trackingNumberNPoshta : ''
        this.paymentStatus = newVal.paymentStatus ? newVal.paymentStatus : ''
        this.countryId = newVal.countryId ? newVal.countryId : ''

        this.userName = newVal.userName ? newVal.userName : ''
        this.userId = newVal.userId ? newVal.userId : ''
        this.sendStatus = newVal.userId ? newVal.sendStatus : ''
      },

    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      getCountrySelected(){
        if(this.countryId === '') return ''
        return this._.find(this.optionsCountries, {id: parseInt(this.countryId)})
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)
        data.date = this.changeDateParams(this, 'date')
        data.deliveryDate = this.changeDateParams(this, 'deliveryDate')

        this.$emit('changeFilter', data)
      },

      changeCountry(val) {
        this.countryId = val.id
      },

      changeStatus(val) {
        this.sendStatus = val.value
      },

      getStatusSelected(){
        if(this.$route.query.sendStatus === '') return ''

        return this.EXPRESS_SEND_STATUSES_ARRAY.find(item => {
          return item.value === this.$route.query.sendStatus
        })
      },

    },
  }
</script>

<style scoped>

</style>

// import {RUSSIA_COUNTRY_ID} from "../../staticData/staticVariables";

import {DHL_DELIVERY_SERVICE} from "../../staticData/staticVariables";

export const warehousesMixin = {
  methods: {
    // getWarehousesFromBelarus(warehouses){
    //   return [this._.find(warehouses, {code: CITY_MINSK.code})]
    // },

    getWarehousesFromCountryGroup(){
      const query = new this.Query();
      let url = '?';
      let myQuery = query
        .for('posts')

      let user = this.isAdmin ? this.ex.express.user : this.$store.getters.getUserProfile,
        userCountryGroups = this.getUserContactDataFromUser(user)?.country_group

      if(this.getUserAlternativeContact(user)) {
        userCountryGroups = this.getUserAlternativeContact(user)?.country_group
      }

      url = url + myQuery.limit(100000).page(parseInt(0)).url().split('?')[1]

      url += `&delivery_service_id=${this.ex.getExpressType()}`

      if(userCountryGroups && userCountryGroups.ids)
        url += `&country_group_id=${userCountryGroups.ids.join(',')}`

      if (this.ex.getExpressType() === DHL_DELIVERY_SERVICE.id && this.ex.getOrderCountryType() !== '' &&
        user && this.isUkrainianByUserData(this.getUserContactDataFromUser(user).country_group) &&
        !this.getUserAlternativeContact(user)) { //DHL
        url += `&order_express_country_type=${this.ex.getOrderCountryType()}`
      }

      return this.$store.dispatch('fetchWarehousesFromExpress', url).then(response => {
        return response.data.data
      })
    },

    // getWarehousesFromRussia(){
    //   const query = new this.Query();
    //   let url = '?';
    //   let myQuery = query
    //     .for('posts')
    //
    //   myQuery.where('warehouseCountry', RUSSIA_COUNTRY_ID)
    //
    //   url = url + myQuery.limit(100000).page(parseInt(0)).url().split('?')[1]
    //
    //   return this.$store.dispatch('fetchWarehouses', url).then(response => {
    //     return response.data.data.data
    //   })
    // }
  }
}

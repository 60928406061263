<template>
	<modal
			@close="$emit('close')"
			class="custom-popup"
	>
		<template slot="header">
			No Money on account ($32)
		</template>
		<template slot="body">
			<div class="custom-popup__content mb-5">
				<p class="fsz14">
					You don’t have enough money to buy the label. Would you like to pay with your dredit card?
				</p>

				<div class="amount-row light mb-4">
					Your Balance
					<span>$32</span>
				</div>

				<div class="order-create__product custom-row">
					<div class="custom-col custom-col--33 custom-col--md-50 custom-col--xs-100 mb-2">
						<div class="order-create__product-input">
							<InputSum
									:value="100"
									:icoType="'dollar'"
									:label="'Sum to be Payed'"
							/>
						</div>
					</div>
				</div>


				<div class="amount-row light">
					Sum with Bank fees
					<span>$33</span>
				</div>
			</div>
		</template>
		<template slot="footer">
			<div class="buy-label__btn d-flex align-items-center">
        <span
						class="site-link site-link--alt buy-label__btn-i mr-3"
						@click="$emit('close')"
				>
          Cancel
        </span>

				<MainButton
						:value="'Pay Now'"
						class="buy-label__btn-i"
						@click.native="$emit('close')"
				/>
			</div>
		</template>
	</modal>

</template>

<script>
	import Modal from '../../../../commonModals/Modal.vue'
	import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
	import InputSum from "../../../../UI/inputs/InputSum/InputSum";

	export default {
		name: "NoMoneyPopup",
		components: {
			Modal,
			MainButton,
			InputSum,
		},

	}

</script>

<style lang="scss">

</style>

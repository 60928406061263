<template>
  <AccordBlock>
    <template slot="header">
      <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
						'express_ProductsDetails',
					])"></div>
        {{ $t('express_ProductsDetails.localization_value.value') }}
      </div>
    </template>
    <template slot="body">
      <ProductsDetails
          :ex="ex"
          :isEdit="isEdit"
          :isAdvanced="true"
          @changeCategory="({val, nameVal, item}) => $emit('changeCategory', {val, nameVal, item})"
          @addProduct="$emit('addProduct')"
          @removeProduct="index => $emit('removeProduct', index)"
      />
    </template>

  </AccordBlock>
</template>

<script>
import AccordBlock from "@/components/coreComponents/AccordBlock/AccordBlock";
import ProductsDetails
  from "@/components/modules/ExpressOrdersModule/components/chunks/UserBlocks/ProductsDetails/ProductsDetails";
export default {
  name: "ProductsDetailsWrap",
  components: {AccordBlock, ProductsDetails},

  props: {
    ex: Object,
    isEdit: {
      type: Boolean,
      default: false,
    },
  },

}
</script>

<style scoped>

</style>
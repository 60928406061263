
export const hsCodeMixin = {
  methods: {
    getHSCodePermissionsByCountry(countries, isRussian = false){
      if(!countries) return false
      if(countries?.country_groups.length === 0) return false

      let fromHsCodeGroup = this._.find(countries.country_groups, 'needHsCodeGroup') //fromHsCodeGroup

      if(!fromHsCodeGroup) return false

      if(fromHsCodeGroup && (this.isUkrainian || this.isPoland || this.isBelarus || this.isBaltic || this.isEAEUGroup || this.isAdmin || isRussian)){ //this.isBelarus ||
        return true
      }

      return false

    },
  }
}

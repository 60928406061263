import {checkValid} from "../../../../../globalModels/functions/ModelsValidate";
import {Delivery} from "../../../../../globalModels/Delivery";

const moment = require('moment')


export function CallingCourier() {

  this.checkValid = checkValid

  this.callingCourier = {
    userName: '',
    userPhone: '',
    timePickup: '',
    arrivalDate: '',
    comment: '',

    isAlternativeAddress: false,

    buildingPart: '',

    Address: new Delivery()
  }

  this.validation = {
    userName: false,
    userPhone: false,
    timePickup: false,
    arrivalDate: false,
    comment: false,
  }

  this.validationTranslate = {
    userName: '',
    userPhone: '',
    timePickup: '',
    arrivalDate: '',
    comment: '',
  }

  this.validationTxt = {
    userName: false,
    userPhone: false,
    timePickup: false,
    arrivalDate: false,
    comment: false,
  }

}


/*****************   SETTERS   ******************/
CallingCourier.prototype.setUserName = function (val) {
  this.callingCourier.userName = val;
}

CallingCourier.prototype.setUserPhone = function (val) {
  this.callingCourier.userPhone = val;
}

CallingCourier.prototype.setTimePickup = function (val) {
  this.callingCourier.timePickup = val;
}

CallingCourier.prototype.setArrivalDate = function (val) {
  this.callingCourier.arrivalDate = val;
}

CallingCourier.prototype.setComment = function (val) {
  this.callingCourier.comment = val;
}

CallingCourier.prototype.setBuildingPart = function (val) {
  this.callingCourier.buildingPart = val;
}

CallingCourier.prototype.setCourierItem = function (val) {
  this.callingCourier.userName = val.userName;
  this.callingCourier.userPhone = val.userPhone;
  this.callingCourier.timePickup = val.timePickup;
  this.callingCourier.arrivalDate = val.arrivalDate;
  this.callingCourier.comment = val.comment;
}


/*****************   GETTERS   ******************/
CallingCourier.prototype.getUserName = function () {
  return this.callingCourier.userName;
}

CallingCourier.prototype.getUserPhone = function () {
  return this.callingCourier.userPhone;
}

CallingCourier.prototype.getTimePickup = function () {
  return this.callingCourier.timePickup;
}

CallingCourier.prototype.getArrivalDate = function () {
  return this.callingCourier.arrivalDate;
}

CallingCourier.prototype.getComment = function () {
  return this.callingCourier.comment;
}

CallingCourier.prototype.getBuildingPart = function () {
  return this.callingCourier.buildingPart;
}

CallingCourier.prototype.prepareCourierItem = function () {
  console.log(this.callingCourier.arrivalDate);
  return {
    'contact_name': this.callingCourier.userName,
    'phone': this.callingCourier.userPhone,
    'from_time': this.callingCourier.timePickup[0],
    'to_time': this.callingCourier.timePickup[1],
    'arrival_date': this.callingCourier.arrivalDate ?
      moment(this.callingCourier.arrivalDate).format('YYYY-MM-DD') :
      '',
    'comment': this.callingCourier.comment,
  }
}

CallingCourier.prototype.prepareCourierFedex = function () {

  let data = {
    arrival_date: this.callingCourier.arrivalDate ?
      moment(this.callingCourier.arrivalDate).format('YYYY-MM-DD') :
      '',
    from_time: this.callingCourier.timePickup[0],
    to_time: this.callingCourier.timePickup[1],
    building_part: this.callingCourier.buildingPart.value,
    comment: this.callingCourier.comment,
    alternative_address: this.callingCourier.isAlternativeAddress ? 1 : 0,
  }

  if (this.callingCourier.isAlternativeAddress) {
    data.alternative_data = {}
    data.alternative_data.contact_name = this.callingCourier.userName
    data.alternative_data.phone = this.callingCourier.userPhone
    data.alternative_data.address = this.callingCourier.Address.getAddress()
    data.alternative_data.city = this.callingCourier.Address.getCity()
    data.alternative_data.region = this.callingCourier.Address.getRegion()
    data.alternative_data.zip_code = this.callingCourier.Address.getZip()
    data.alternative_data.country_id = this.callingCourier.Address.getCountry().id
  }

  return data
}


/*****************   VALIDATION   ******************/

CallingCourier.prototype.callingCourierValidate = function (
  {
    userName,
    userPhone,
    timePickup,
    arrivalDate,
    comment,
  }
) {

  let validationItems = {
    userName: this.callingCourier.userName,
    userPhone: this.callingCourier.userPhone,
    timePickup: this.callingCourier.timePickup,
    arrivalDate: this.callingCourier.arrivalDate,
    comment: this.callingCourier.comment,
  }

  let validationOptions = {
    userName: {type: userName ? ['latin', 'empty'] : []},
    userPhone: {type: userPhone ? ['phone', 'empty'] : []},
    timePickup: {type: timePickup ? ['empty'] : []},
    arrivalDate: {type: arrivalDate ? ['empty'] : []},
    comment: {type: comment ? ['empty'] : []},
  }


  return this.checkValid(validationItems, validationOptions)
}

CallingCourier.prototype.callingCourierValidateRangeTime = function () {
  /**
   * Checking min range of the select Time
   */
  let checkRangeTime = true
  let date = "2000-01-01"
  let startDate = moment(date + ' ' + this.callingCourier.timePickup[0])
  let endDate = moment(date + ' ' + this.callingCourier.timePickup[1])

  startDate = startDate.startOf('hour').add(2, 'hours').format('YYYY-MM-DD HH:mm')

  if (moment(startDate).isAfter(endDate)) {
    console.log(moment(startDate).isAfter(endDate));
    checkRangeTime = false
    this.validation.timePickup = true
    this.validationTranslate.timePickup = 'error_rangeTimeFedex'
  }

  return checkRangeTime
}

<template>
  <modal
      @close="$emit('close')"
      class="custom-popup order-details-popup"
  >
    <template slot="header">
      Order Details
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="simple-table">
          <div class="simple-table__inner">
            <table>
              <tr>
                <th colspan="2">Currently</th>
              </tr>
              <tr>
                <td>
                  <span class="site-status blue">
                    <StatusIcoBtn :type="'in-transit'" class=""/>
                    <span>In Transit</span>
                  </span>
                </td>
                <td>Good condition</td>
              </tr>
              <tr>
                <th colspan="2">History</th>
              </tr>
              <tr>
                <td>
                  <span class="site-status green">
                    <StatusIcoBtn :type="'active'" />
                    <span>Delivered</span>
                  </span>
                </td>
                <td>Good condition. Dormund 22 Aug 2020 at 9:09</td>
              </tr>
              <tr>
                <td>
                  <span class="site-status orange">
                    <StatusIcoBtn :type="'out-for-delivery'" />
                    <span>Out for Delivery</span>
                  </span>
                </td>
                <td>Good condition. Dormund 22 Aug 2020 at 6:41</td>
              </tr>
              <tr>
                <td>
                  <span class="site-status blue">
                    <StatusIcoBtn :type="'in-transit'" />
                    <span>In Transit</span>
                  </span>
                </td>
                <td>Kiege Euro Hub 8 Aug 2020 at 00:45</td>
              </tr>
              <tr>

                <td>
                  <span class="site-status violet">
                    <StatusIcoBtn :type="'received'" />
                    <span>Received</span>
                  </span>
                </td>
                <td>KGood condition. Dormund 22 Aug 2020 at 6:41</td>
              </tr>
              <tr>
                <td>
                  <span class="site-status accent">
                    <StatusIcoBtn :type="'collected'" />
                    <span>Collected</span>
                  </span>
                </td>
                <td>Kiege Euro Hub 8 Aug 2020 at 00:45</td>
              </tr>
            </table>
          </div>
        </div>

      </div>
    </template>
  </modal>

</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import StatusIcoBtn from "../../../../UI/status/StatusIcoBtn/StatusIcoBtn";

  export default {
    name: "StatusOrderDetailsPopup",
    components: {
      Modal,
      StatusIcoBtn,
    },

  }

</script>

<style scoped>


  .simple-table{
    margin-left: -48px;
    margin-right: -40px;
  }
  .simple-table__inner th:first-child,
  .simple-table__inner td:first-child{
    padding-left: 48px;
  }

  .simple-table__inner th:last-child,
  .simple-table__inner td:last-child{
    padding-right: 40px;
  }

  .order-details-popup{
    z-index: 999;
  }

  @media (max-width: 768px) {
    .simple-table{
      margin-left: -15px;
      margin-right: -15px;
    }
    .simple-table__inner th:first-child,
    .simple-table__inner td:first-child{
      padding-left: 15px;
    }

    .simple-table__inner th:last-child,
    .simple-table__inner td:last-child{
      padding-right: 15px;
    }
  }

</style>

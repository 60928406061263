import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {PersonalInformation} from "../../../globalModels/PersonalInformation";
import {Delivery} from "../../../globalModels/Delivery";
import {Packaging} from "../../../globalModels/Packaging";
import {CDEK_DELIVERY_SERVICE} from "../../../../staticData/staticVariables";

const moment = require('moment')
const _ = require('lodash');

export function CDEK() {

  this.checkValid = checkValid

  this.data = {
    userId: -1,
    user: {},
    id: '',
    type: '',
    sourceOrderId: '',

    paymentTransactionId: '',
    typeTransaction: '',
    typeTransactionId: '',

    senderPersonalInfo: {},

    personalInformation: new PersonalInformation(),
    personalAddress: new Delivery(),
    // deliveryAddress: new Delivery(),

    shippingWarehouse: {},

    packaging: new Packaging(),

    products: [],

    item: {},

    senderAddress: {},

    zip: '',
    warehouse: '',
    selectedCity: {},
    selectedOffice: {},
    shippingDate: '',
    assessedValue: '',

    serviceType: '',

    trackingNumber: '',
    shippingCost: '',

  }

  this.validation = {
    assessedValue: false,
    shippingDate: false,
    // city: false,
    // address: false,
    selectedCity: false,
    selectedOffice: false,
    zip: false,
    shippingWarehouse: false,
  }

  this.validationTranslate = {
    assessedValue: '',
    shippingDate: '',
    // city: '',
    // address: '',
    selectedCity: '',
    selectedOffice: '',
    zip: '',
    shippingWarehouse: '',
  }

  this.validationTxt = {
    assessedValue: false,
    shippingDate: false,
    // city: false,
    // address: false,
    selectedCity: false,
    selectedOffice: false,
    zip: false,
    shippingWarehouse: false,
  }

}


/*****************   SETTERS   ******************/

CDEK.prototype.setType = function (val) {
  this.data.type = val;
}

CDEK.prototype.setOrderType = function (id, type) {
  this.setSourceOrderId(id)
  this.setType(type)
}

CDEK.prototype.setSourceOrderId = function (val) {
  this.data.sourceOrderId = val
}

CDEK.prototype.setId = function (val) {
  this.data.id = val;
}

CDEK.prototype.setUserId = function (val) {
  this.data.userId = val;
}

CDEK.prototype.setPaymentTransactionId = function (val) {
  this.data.paymentTransactionId = val;
}

CDEK.prototype.setCity = function (val) {
  console.log(val);
  this.data.city = val;
}

CDEK.prototype.setAddress = function (val) {
  this.data.address = val;
}

CDEK.prototype.setWarehouse = function (val) {
  this.data.warehouse = val;
}

CDEK.prototype.setZip = function (val) {
  this.data.zip = val;
}

CDEK.prototype.setShippingDate = function (val) {
  this.data.shippingDate = val;
}

CDEK.prototype.setAssessedValue = function (val) {
  this.data.assessedValue = val ? val : ''
}

CDEK.prototype.setProducts = function (products) {
  console.log(777, 'CDEK.prototype.setProducts: ', products);

  let items = []

  // products.map((item) => {
  //   items.push({
  //     'name': item[0].PROFORM_TYPE_NAMING.value.translationStorage,
  //     'price': item.data.price,
  //     'quantity': item.data.count,
  //     'total': item.data.totalSum,
  //   })
  // })

  this.data.products = items;
}

CDEK.prototype.setCDEKUser = function (user) {
  let userInformation

  this.data.user = user

  this.setUserId(user.id)

  if (user?.contacts) {
    userInformation = _.first(user?.contacts)
  } else {
    userInformation = user?.user_personal_contact
  }

  this.writingDataCurrentUser(userInformation)
}

CDEK.prototype.setShippingWarehouse = function (val) {
  let deliveryService = _.first(val["delivery_service_settings"])

  this.setWarehouse(deliveryService.city)

  let data = {
    "delivery_service_id": deliveryService["delivery_service_id"],
    "recipient_warehouse_ref": deliveryService["warehouse_ref"],
    "recipient_warehouse_id": deliveryService["warehouse_id"],
    "recipient_city_ref": deliveryService["city_ref"],
  }

  this.data.shippingWarehouse = data
}
// CDEK.prototype.setShippingWarehouseEditing = function (val) {
//
//   console.log(val);
//
//   this.data.shippingWarehouse = val
// }

CDEK.prototype.setItem = function (val) {

  let data = this.data

  if (val.user) {
    this.setCDEKUser(val.user)
  }

  data.trackingNumber = val.tracking_number ? val.tracking_number : ''
  data.shippingCost = val.shipping_cost ? val.shipping_cost : ''

  this.data.shippingWarehouse = {
    recipient_city_ref: val['recipient_city_ref'] ? val['recipient_city_ref'] : '',
    recipient_city_name: val["recipient_warehouse"]["delivery_service_settings"][0]["city"] ?
      val["recipient_warehouse"]["delivery_service_settings"][0]["city"] : ''
  }

  this.setSourceOrderId(val['order_express_id'])

  data.personalInformation.setPersonalItem({
    personalName: val['sender_full_name'],
    personalPhone: val['sender_phone'] ? val['sender_phone'].replace(/[^\d]/g, '') : '',
    personalEmail: val['sender_email'],
  })

  data.personalAddress.setDeliveryItem({
    country: val['sender_country'] ? {
      id: val['sender_country'].id,
      label: val['sender_country'].name,
    } : '',
    zip: val['sender_zip'],
  })

  data.packaging.setPackagingItem({
    weight: parseFloat(val.weight).toFixed(2),
    height: parseFloat(val.height).toFixed(2),
    width: parseFloat(val.width).toFixed(2),
    length: parseFloat(val['length']).toFixed(2),
  })

  // this.setSenderAddress({
  //   cityName: val.sender_city,
  //   senderAddress: val.sender_address
  // })

  this.setSelectedCity({
    'id': val['sender_city_ref'],
    'cityName': val['sender_city'],
  })

  this.setSelectedOffice({
    address: val['sender_address'],
    code: val['sender_warehouse_ref']
  })

  this.setZip(val['sender_zip'])

  this.setAssessedValue(val['declared_value'])

  this.setShippingDate(
    val["shipped_at"] ? moment(val["shipped_at"]).format('YYYY-MM-DD') : ''
  )

  this.setServiceType("WarehouseWarehouse")

  this.data.item = data

}

CDEK.prototype.setOldSenderData = function (val) {

  this.setSelectedCity({
    'id': val['city_ref'],
    'cityName': val['city'],
  })

  this.setSelectedOffice({
    address: val['address'],
    code: val['warehouse_ref']
  })

  this.setZip(val['zip'])

  this.data.personalInformation.setPersonalItem({
    personalName: val['full_name'],
    personalPhone: val['phone'],
  })
}

CDEK.prototype.setSenderAddress = function (val) {
  this.data.senderAddress = val
}

CDEK.prototype.setSelectedCity = function (val) {
  this.data.selectedCity = val
}

CDEK.prototype.setSelectedOffice = function (val) {
  this.data.selectedOffice = val
}

CDEK.prototype.setServiceType = function (val) {
  this.data.serviceType = val
}


/*****************   GETTERS   ******************/
CDEK.prototype.getType = function () {
  return this.data.type
}

CDEK.prototype.getId = function () {
  return this.data.id
}

CDEK.prototype.getUserId = function () {
  return this.data.userId
}

CDEK.prototype.getPaymentTransactionId = function () {
  return this.data.paymentTransactionId
}

CDEK.prototype.getCity = function () {
  return this.data.city
}

CDEK.prototype.getAddress = function () {
  return this.data.address
}

CDEK.prototype.getWarehouse = function () {
  return this.data.warehouse
}

CDEK.prototype.getPrice = function () {
  return this.data.price
}

CDEK.prototype.getAssessedValue = function () {
  return this.data.assessedValue
}

CDEK.prototype.getShippingDate = function () {
  return this.data.shippingDate
}

CDEK.prototype.getProducts = function () {
  return this.data.products
}

CDEK.prototype.getSenderPersonalInfo = function () {
  return this.data.senderPersonalInfo;
}

CDEK.prototype.getZip = function () {
  return this.data.zip
}

CDEK.prototype.getShippingWarehouse = function () {
  return this.data.shippingWarehouse
}

CDEK.prototype.getSenderAddress = function () {
  return this.data.senderAddress
}

CDEK.prototype.getSelectedCity = function () {
  return this.data.selectedCity
}

CDEK.prototype.getSelectedOffice = function () {
  return this.data.selectedOffice
}

CDEK.prototype.getServiceType = function () {
  return this.data.serviceType
}


/*******************   FUNCTIONS   *************/

CDEK.prototype.writingDataCurrentUser = function (userInfo) {

  let data = this.data


  data.personalInformation.setPersonalItem({
    personalName: userInfo["user_full_name"],
    personalPhone: userInfo.phone ? userInfo.phone.replace(/[^\d]/g, '') : '',
    personalEmail: userInfo.email,
  })

  data.personalAddress.setDeliveryItem({
    address: userInfo.address,
    city: userInfo.city,
    country: userInfo.country ? {
      id: userInfo.country.id,
      label: userInfo.country.name,
    } : '',
    zip: userInfo.zip,
  })
}

/******************  VALIDATIONS  *****************/


CDEK.prototype.firstValidation = function () {

  let data = this.data

  let validationItems = {
    // city: data.city,
    // address: data.address,
    selectedCity: data.selectedCity,
    selectedOffice: data.selectedOffice,
    zip: data.zip,
    warehouse: data.warehouse,
    assessedValue: data.assessedValue,
    shippingDate: data.shippingDate,
  }

  let validationOptions = {
    // city: {type: ['empty']},
    // address: {type: ['empty']},
    selectedCity: {type: ['empty']},
    selectedOffice: {type: ['empty']},
    zip: {type: ['empty', 'numeric']},
    warehouse: {type: ['empty']},
    assessedValue: {type: data.assessedValue !== '' ? ['empty', 'numeric'] : []},
    shippingDate: {type: ['empty']}
  }

  let personalInfoValid = data.personalInformation.personalInfoPartsValidate({
    personalName: true,
    personalPhone: true,
    nameNoLatin: true
  })

  let packagingValid = data.packaging.packagingPartialValidate({
    weight: true,
    height: true,
    width: true,
    packageLength: true,
  })

  return (this.checkValid(validationItems, validationOptions) &&
    personalInfoValid &&
    packagingValid)
}


/******************  PREPARE DATA  *****************/

CDEK.prototype.prepareData = function () {
  let poshta = this.data
  let packaging = this.data.packaging.preparePackagingItem()
  let personalInfo = this.data.personalInformation.information
  let personalAddress = this.data.personalAddress.delivery


  let data = {
    "delivery_service_id": CDEK_DELIVERY_SERVICE.id,

    "shipped_at": poshta.shippingDate,

    "weight": packaging.weight,
    "length": packaging['length'],
    "width": packaging.width,
    "height": packaging.height,

    "volume_weight": this.data.packaging.getVolumeWeight(),
    "volume_general": this.data.packaging.getVolumeGeneral(),
    "declared_value": this.getAssessedValue(),

    "sender_full_name": personalInfo.personalName,
    "sender_phone": personalInfo.personalPhone,
    "sender_email": personalInfo.personalEmail,
    "sender_country_id": personalAddress.country.id,
    "sender_address": this.getSelectedOffice().address,
    "sender_zip": poshta.zip,
    "sender_city": this.getSelectedCity().cityName,


    "sender_warehouse_ref": this.getSelectedOffice().code,
    "sender_city_ref": this.getSelectedCity().id + '',

    "recipient_warehouse_ref": this.getShippingWarehouse().recipient_warehouse_ref,
    "recipient_warehouse_id": this.getShippingWarehouse().recipient_warehouse_id,
    "recipient_city_ref": this.getShippingWarehouse().recipient_city_ref,

    "service_type": "WarehouseWarehouse",
    // "shipment_id" : '',

    "user_id": poshta.userId,
  }

  if (poshta.sourceOrderId !== '') {
    data['order_express_id'] = parseInt(poshta.sourceOrderId)
  }

  return data
}


import Modal from '../../../../commonModals/Modal.vue'
import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'

export default {
	name: "CancelOrderCreation",
	components: {
		Modal,
		MainButton,
	}
}

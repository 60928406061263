<template>
  <modal
      @close="$emit('close')"
      class="custom-popup medium-size"
  >
    <template slot="header">
      <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta_SenderWarehouse'])"></div>
        {{ $t('poshta_SenderWarehouse.localization_value.value') }}
      </div>

    </template>
    <template slot="body">

      <div class="custom-popup__content mb-4">


        <div class="custom-row">
          <div class="transaction-info__col justify-content-center">
            <div class="transaction-info__txt"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['poshta_MyWarehouses'])"></div>
              {{ $t('poshta_MyWarehouses.localization_value.value') }}
            </div>
          </div>
<!--          <div class="transaction-info__col align-items-end"-->
<!--               v-if="serviceType === NOVAPOSHTA_SERVICE_TYPES.doorsWarehouse"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit right" @click="editTranslate(['poshta_AddAddress'])"></div>-->
<!--            <MainButton-->
<!--                :value="$t('poshta_AddAddress.localization_value.value')"-->
<!--                class="buy-label__btn-i wfc"-->
<!--                @click.native="$emit('openControl')"-->
<!--            />-->
<!--          </div>-->
        </div>

        <div class="custom-row">
          <div class="custom-col">
            <div class="nova-poshta-sender-address mt-3">
              <div class="nova-poshta-sender-address__item"
                   :class="{'nova-poshta-sender-address__item--active' : savedAddressSelected && item.ref_warehouse === savedAddressSelected.ref_warehouse}"
                   v-for="(item, index) in userWarehouses"
                   :key="index"
                   @click="chooseSenderWarehouse(item)"
              >
                {{item.warehouse_name}}
              </div>
            </div>
          </div>
        </div>


<!--        <template-->
<!--            v-if="serviceType.value === NOVAPOSHTA_SERVICE_TYPES.warehouseWarehouse.value"-->
<!--        >-->
          <div class="section-label"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['poshta_AnotherWarehouse'])"></div>
            {{ $t('poshta_AnotherWarehouse.localization_value.value') }}
          </div>

          <div class="custom-row">
            <div class="custom-col"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['poshta_SearchCity'])"></div>
              <SearchSelect
                  :options="regions"
                  :label="$t('poshta_SearchCity.localization_value.value')"
                  :functionSearch="functionSearchNovaPoshtaRegion"
                  :optionsLabel="'label'"
                  :selected="region"
                  @change="selectNovaPoshtaRegion"
              />
            </div>
          </div>

          <div class="custom-row">
            <div class="custom-col">
              <DefaultSelect
                  v-if="novaPoshtaWarehouses.length > 0"
                  :options="novaPoshtaWarehouses"
                  :label="''"
                  :otherValue="'novaPoshtaDepartment'"
                  :selected="department"
                  @change="chooseNovaPoshtaWarehouse"
              />
<!--              <div class="nova-poshta-sender-address mt-3">-->
<!--                <div class="nova-poshta-sender-address__item"-->
<!--                     v-for="(item, index) in novaPoshtaWarehouses"-->
<!--                     :class="{'nova-poshta-sender-address__item&#45;&#45;active' : item.ref_warehouse === ex.express.novaPoshtaAddress.ref_warehouse}"-->
<!--                     :key="index"-->
<!--                     @click="chooseNovaPoshtaWarehouse(item)"-->
<!--                >-->
<!--                  {{item.warehouse['Description']}}-->
<!--                  &lt;!&ndash;                Kyiv, Kyivsky dist.&ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
<!--        </template>-->

      </div>
    </template>
    <template slot="footer">
      <div class="buy-label__btn d-flex align-items-center"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_cancel', 'poshta_Apply'])"></div>
        <span
            class="site-link site-link--alt buy-label__btn-i mr-3"
            @click="$emit('close')"
        >
          {{ $t('common_cancel.localization_value.value') }}
        </span>

        <MainButton
            class="buy-label__btn-i wfc"
            :value="$t('poshta_Apply.localization_value.value')"
            @click.native="applyPopup"
            v-bind:class="{'disabled-btn' : !department && !savedAddressSelected}"
        />
      </div>
    </template>
  </modal>

</template>

<script>
import Modal from '../../../../commonModals/Modal.vue'
import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
import {radioButtonsFunc} from "../../../../../mixins/creationPageMixins/radioButtonsFunc";
// import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
import SearchSelect from "../../../../UI/selectiones/SearchSelect/SearchSelect";
import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";

export default {
  name: "NovaPoshtaAddressPopup",

  components: {
    DefaultSelect,
    SearchSelect,
    Modal,
    MainButton,
    // DefaultInput,
  },

  mixins: [radioButtonsFunc],

  props: {
    ex: Object,
  },

  computed: {
    checkSelectedWarehouse() {

      return false
    }
  },

  data() {
    return {
      userWarehouses: [],
      regions: [],
      region: null,
      department: null,
      savedAddressSelected: null,
      novaPoshtaWarehouses: [],
    }
  },

  created() {

    // let data = {}
    // if(this.$store.getters.getIsAdminRights === 'admin'){
    //   if(this.ex.getUserId() === '') return
    //
    //   data['user_id'] = this.ex.getUserId()
    // }
    //
    this.$store.dispatch('getFBMNovaPoshtaAddresses').then(response => {
      this.userWarehouses = this.getRespData(response)

      if(this._.find(this.userWarehouses, {ref_warehouse: this.ex.express.novaPoshtaAddress?.ref_warehouse})) {
        this.savedAddressSelected = this.ex.express.novaPoshtaAddress
        return
      }

      if(this.ex.express.novaPoshtaAddress) {
        this.region = {label: this.ex.express.novaPoshtaAddress?.city_name}
        this.department = {
          translationStorage: {ua:
                {
                  name: this.ex.express.novaPoshtaAddress?.warehouse_name,
                  city: this.ex.express.novaPoshtaAddress?.city_name,
                }
          }
        }
        this.novaPoshtaWarehouses.push(this.department)
      }

    })


  },

  methods: {
    chooseSenderWarehouse(item){
      this.savedAddressSelected = item
      this.department = null
    },

    applyPopup() {

      if(this.department) {
        this.ex.express.novaPoshtaAddress =  {
          "address_type": "Warehouse",
          "ref_warehouse": this.department.reference,
          "warehouse_name": this.department.translationStorage.ua.name,
          "city_name": this.department.translationStorage.ua.city
        }
      }

      if(this.savedAddressSelected) {
        this.ex.express.novaPoshtaAddress = this.savedAddressSelected
      }

      this.$emit('close')

    },

    functionSearchNovaPoshtaRegion(val, loading) {
      loading(true)

      if (this.proformSearchTimeoutTime !== undefined) {
        let dateNow = new Date()
        if (dateNow - this.proformSearchTimeoutTime < 500) {
          clearTimeout(this.proformSearchTimeout)
        }
      }

      this.createTimeOut(val, loading)
    },

    createTimeOut(val, loading) {
      this.proformSearchTimeoutTime = new Date()
      this.proformSearchTimeout = setTimeout(() => {
        this.getRegion(val, loading)
      }, 500)
    },

    getRegion(val = false, loading){

      if(val && val.length < 3) return

      this.$store.dispatch('getFBMNovaPoshtaRegion', val).then((response) => {
        this.regions = response?.data[0] || []
        if(loading)
          loading(false)
      }).catch(error => this.createErrorLog(error))
    },

    selectNovaPoshtaRegion(val) {
      this.region = val

      this.$store.dispatch('getFBMNovaPoshtaDepartment', val?.label).then((response) => {
        this.novaPoshtaWarehouses = this.getRespData(response)
      })
    },

    chooseNovaPoshtaWarehouse(item) {
      this.department = item
      this.savedAddressSelected = null
    },

  },

}

</script>

<style scoped lang="scss">
@import '../../../../../scss/colors';



.nova-poshta-sender-address{
  display: flex;
  flex-wrap: wrap;

  &__item{
    padding: 5px 12px;
    background: $borderBrown;
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: 4px;
    transition: 0.3s;
    color: $white;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;

    &:hover,&--active{
      background: $brown;
    }
  }
}
</style>

import {Express} from "./Express";

Express.prototype.getExpressType = function () {
  return this.express.type
}

Express.prototype.getWarehouse = function () {
  return this.express.warehouse
}

Express.prototype.getFedexPackingType = function () {
  return this.express.fedexPackingType
}

Express.prototype.getTrackingNumber = function () {
  return this.express.trackingNumber
}

Express.prototype.getSenderContactName = function () {
  return this.express.senderContactName
}

Express.prototype.getSenderPhone = function () {
  return this.express.senderPhone
}

Express.prototype.getDeliveryDate = function () {
  return this.express.deliveryDate
}

Express.prototype.getTimeCourierTo = function () {
  return this.express.timeCourierTo
}

Express.prototype.getTimeCourierFrom = function () {
  return this.express.timeCourierFrom
}

Express.prototype.getTimeNotAvailableTo = function () {
  return this.express.timeNotAvailableTo
}

Express.prototype.getTimeNotAvailableFrom = function () {
  return this.express.timeNotAvailableFrom
}

Express.prototype.getOrderPrice = function () {
  return this.express.orderPrice
}

Express.prototype.getFreightCost = function () {
  return this.express.freightCost
}

Express.prototype.getVisibleFreightCost = function () {
  return this.express.visibleFreightCost
}

Express.prototype.getRegions = function () {
  return this.express.regions
}

Express.prototype.getVisibleHSCode = function () {
  return this.express.visibleHSCode
}

Express.prototype.getVatNumber = function () {
  return this.express.vatNumber
}

Express.prototype.getVisibleVatNumber = function () {
  return this.express.visibleVatNumber
}

Express.prototype.getTotalSumProduct = function () {
  let totalSum = 0,
    ex = this.express
  ex.products.map(item => {
    totalSum += parseFloat(item.product.price * item.product.itemsQuantity)
  })
  return parseFloat(totalSum)
}

Express.prototype.getExpressTrack = function () {
  return this.express.expressTrack
}

Express.prototype.getOrderCountryType = function () {
  return this.express.orderCountryType
}


export const ExpressMixinDeliveryData = {
  methods: {


    /**
     * Personal Data Update
     */
    setPopupInformationData() {
      this.changeDataPopup = [
        {
          field: 'name',
          name: 'express_ContactName',
          type: 'text',
          value: this.PI.information.personalName,
          valid: this.PI.validation.personalName,
          validTxt: this.PI.validationTranslate.personalName,
        },
        {
          field: 'phone',
          name: 'common_phone',
          type: 'text',
          value: this.PI.information.personalPhone,
          valid: this.PI.validation.personalPhone,
          validTxt: this.PI.validationTranslate.personalPhone,
        },
        {
          field: 'email',
          name: 'express_Email',
          type: 'text',
          value: this.PI.information.personalEmail,
          valid: this.PI.validation.personalEmail,
          validTxt: this.PI.validationTranslate.personalEmail,
        },
      ]
    },

    personalUpdate(data) {
      this.PI.setPersonalName(this._.find(data, {field: 'name'}).value)
      this.PI.setPersonalPhone(this._.find(data, {field: 'phone'}).value)
      this.PI.setPersonalEmail(this._.find(data, {field: 'email'}).value)

      let validate = this.PI.personalInfoPartsValidate({
        personalName: true,
        // personalPhone: true,
        emailOptional: true,
      })

      if(!validate){
        this.setPopupInformationData()
        return
      }

      this.isModalChangeAddressPopup = false

      this.ex.express.personal.setPersonalItem({
        personalName: this._.find(data, {field: 'name'}).value,
        personalPhone: this._.find(data, {field: 'phone'}).value,
        personalEmail: this._.find(data, {field: 'email'}).value,
      })
    },



    /**
     * Delivery Data Update
     */
    setPopupDeliveryData() {
      this.changeDataPopup = [
        {
          field: 'country',
          name: 'common_country',
          type: 'select',
          value: this.Delivery.delivery.country,
          valid: this.Delivery.validation.country,
          validTxt: this.Delivery.validationTranslate.country,
        },
        {
          field: 'address',
          name: 'common_address',
          type: 'text',
          value: this.Delivery.delivery.address,
          valid: this.Delivery.validation.address,
          validTxt: this.Delivery.validationTranslate.address,
          maxLength: 35,
          autocomplete: 'address-line1',
        },
        {
          field: 'address2',
          name: 'common_addressSecond',
          type: 'text',
          value: this.Delivery.delivery.address2,
          valid: this.Delivery.validation.address2,
          validTxt: this.Delivery.validationTranslate.address2,
          maxLength: 35,
          autocomplete: 'address-line2',
        },
        {
          field: 'city',
          name: 'common_city',
          type: 'text',
          value: this.Delivery.delivery.city,
          valid: this.Delivery.validation.city,
          validTxt: this.Delivery.validationTranslate.city,
          autocomplete: 'address-level2',
        },
        {
          field: 'region',
          name: 'common_region',
          type: 'text',
          value: this.Delivery.delivery.region,
          valid: this.Delivery.validation.region,
          validTxt: this.Delivery.validationTranslate.region,
          autocomplete: 'address-level1',
        },
        {
          field: 'zip',
          name: 'common_zipCode',
          type: 'text',
          value: this.Delivery.delivery.zip,
          valid: this.Delivery.validation.zip,
          validTxt: this.Delivery.validationTranslate.zip,
          autocomplete: 'postal-code',
        },
      ]
    },

    deliveryUpdate(data) {
      this.Delivery.setAddress(this._.find(data, {field: 'address'}).value)
      this.Delivery.setAddress2(this._.find(data, {field: 'address2'}).value)
      this.Delivery.setCity(this._.find(data, {field: 'city'}).value)
      this.Delivery.setRegion(this._.find(data, {field: 'region'}).value)
      this.Delivery.setCountry(this._.find(data, {field: 'country'}).value)
      this.Delivery.setZip(this._.find(data, {field: 'zip'}).value)

      let validate = this.Delivery.deliveryInfoValidate()

      if(!validate){
        this.setPopupDeliveryData()
        return
      }

      this.isModalChangeAddressPopup = false

      this.ex.express.delivery.setDeliveryItem({
        address: this._.find(data, {field: 'address'}).value,
        address2: this._.find(data, {field: 'address2'}) ? this._.find(data, {field: 'address2'}).value : '',
        city: this._.find(data, {field: 'city'}).value,
        region: this._.find(data, {field: 'region'}).value,
        country: this._.find(data, {field: 'country'}).value,
        zip: this._.find(data, {field: 'zip'}).value,
      })

      this.$emit('reload')

    },
  }
}

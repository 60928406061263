<template>
  <div class="order-create__section" v-if="ex.express.sendType === ex.express.sendTypes.sender">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta__PersonalInformation'])"></div>
      {{$t('poshta__ServiceType.localization_value.value')}}
    </div>

<!--    <div class="order-create__section-label section-label"-->
<!--         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--      <div class="admin-edit" @click="editTranslate(['poshta__ServiceType'])"></div>-->
<!--      {{$t('poshta__ServiceType.localization_value.value')}}-->
<!--    </div>-->

    <div class="custom-row">
<!--      <div class="custom-col custom-col&#45;&#45;66 custom-col&#45;&#45;md-100"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['poshta__PersonalAddress'])"></div>-->
<!--        <RadioDefault-->
<!--            class="white-space-line"-->
<!--            :label="$t('poshta__PersonalAddress.localization_value.value')"-->
<!--            :name="'insuranceRadio'"-->
<!--            :value="ex.express.serviceType === ex.express.serviceTypes.doors"-->
<!--            @input="ex.express.serviceType = ex.express.serviceTypes.doors"-->
<!--        />-->

<!--      </div>-->
      <div class="custom-col custom-col--66 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta__NovaPoshtaAddress', 'poshta__SenderAddress'])"></div>
        <RadioDefault
            class="white-space-line"
            :label="$t('poshta__NovaPoshtaAddress.localization_value.value')"
            :name="'insuranceRadio'"
            :value="ex.express.serviceType === ex.express.serviceTypes.department"
            @input="ex.express.serviceType = ex.express.serviceTypes.department"
        />

        <DefaultInput
            v-if="ex.express.serviceType === ex.express.serviceTypes.department"
            class="ml-4 mt-3"
            :label="$t('poshta__SenderAddress.localization_value.value')"
            :type="'text'"
            @click.native="addressPopupChange(true)"
            :value="ex.express.novaPoshtaAddress ? ex.express.novaPoshtaAddress.warehouse_name : ''"

            v-bind:class="{'ui-no-valid': ex.validation.novaPoshtaAddress}"
            :errorTxt="$t(`${ex.validationTranslate.novaPoshtaAddress}.localization_value.value`)"
            :error="ex.validation.novaPoshtaAddress"

            :readonly="true"
        />
      </div>
    </div>

    <NovaPoshtaAddressPopup
        v-if="addressPopup"
        :ex="ex"
        @close="addressPopupChange(false)"
    />

  </div>
</template>

<script>
import RadioDefault from "@/components/UI/radiobuttons/RadioDefault/RadioDefault";
import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
import NovaPoshtaAddressPopup
  from "@/components/modules/ExpressOrdersModule/popups/NovaPoshtaAddressPopup/NovaPoshtaAddressPopup";
export default {
name: "NovaPoshtaSender",
  components: {
    NovaPoshtaAddressPopup,
    DefaultInput,
    RadioDefault
  },

  props: {
    ex: Object,
  },

  data() {
    return {
      addressPopup: false,
    }
  },

  methods: {


    addressPopupChange(val){
      this.addressPopup = val
    },
  }

}
</script>

<style scoped>

</style>
<template>
  <AccordBlock>
    <template slot="header">
      <div
          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'ex_sendFrom',
        ])"></div>
        {{ $t('ex_sendFrom.localization_value.value') }}
      </div>
    </template>
    <template slot="body">
      <PickupInfo
          :ex="ex"
          :isEdit="isEdit"
      />

      <NovaPoshtaSender
          v-if="ex.express.type === NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id"
          :ex="ex"
      />
    </template>
  </AccordBlock>
</template>

<script>
import AccordBlock from "@/components/coreComponents/AccordBlock/AccordBlock";
import PickupInfo from "@/components/modules/ExpressOrdersModule/components/chunks/UserBlocks/PickupInfo/PickupInfo";
import NovaPoshtaSender
  from "@/components/modules/ExpressOrdersModule/components/chunks/UserBlocks/NovaPoshtaSender/NovaPoshtaSender";
import {NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE} from "@/staticData/staticVariables";

export default {
  name: "PikupInfoWrap",
  components: {NovaPoshtaSender, PickupInfo, AccordBlock},

  props: {
    ex: Object,
    isEdit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE: NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE,
    }
  }

}
</script>

<style scoped>

</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order-create__section"},[_c('div',{staticClass:"order-create__title-horizontal",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'common_pickupInfo',
        ])}}}),_c('TitleHorizontal',{attrs:{"value":_vm.$t('common_pickupInfo.localization_value.value'),"rightBtn":true,"rightBtnType":'edit',"rightBtnText":_vm.$t('common_edit.localization_value.value')},on:{"rightBtnClick":function($event){return _vm.$emit('changeStep', 1)}}})],1),(_vm.AUTHORIZED = _vm.getUserContacts)?_c('div',{staticClass:"order-create__row custom-row"},[_c('div',{staticClass:"order-create__col custom-col",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'common_address',
          'common_city',
          'common_region',
          'common_country',
          'common_zipCode',
        ])}}}),_c('div',{staticClass:"order-create__table block-table"},[_c('div',{staticClass:"block-table__name"},[_vm._v(" "+_vm._s(_vm.$t('ex_sender.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__row"},[_c('div',{staticClass:"block-table__col block-table__col--20"},[_c('div',{staticClass:"block-table__label"},[_vm._v(" "+_vm._s(_vm.$t('common_address.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content"},[(_vm.ex.express.sendType === _vm.ex.express.sendTypes.warehouse &&
									_vm.ex.express.warehouse && _vm.ex.express.warehouse !== '')?[_vm._v(" "+_vm._s(_vm.ex.express.warehouse.sender_address)+" ")]:(Object.keys(_vm.ex.express.user).length > 0)?[_vm._v(" "+_vm._s(_vm.getUserContactsBySelectedUser.address)+" ")]:[_vm._v(" "+_vm._s(_vm.AUTHORIZED.address)+" ")]],2)]),_c('div',{staticClass:"block-table__col block-table__col--20"},[_c('div',{staticClass:"block-table__label"},[_vm._v(" "+_vm._s(_vm.$t('common_city.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content"},[(_vm.ex.express.sendType === _vm.ex.express.sendTypes.warehouse &&
									_vm.ex.express.warehouse && _vm.ex.express.warehouse !== '')?[_vm._v(" "+_vm._s(_vm.ex.express.warehouse.sender_city)+" ")]:(Object.keys(_vm.ex.express.user).length > 0)?[_vm._v(" "+_vm._s(_vm.getUserContactsBySelectedUser.city)+" ")]:[_vm._v(" "+_vm._s(_vm.AUTHORIZED.city)+" ")]],2)]),_c('div',{staticClass:"block-table__col block-table__col--20"},[_c('div',{staticClass:"block-table__label"},[_vm._v(" "+_vm._s(_vm.$t('common_country.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content"},[(_vm.ex.express.sendType === _vm.ex.express.sendTypes.warehouse &&
									_vm.ex.express.warehouse && _vm.ex.express.warehouse !== '')?[_vm._v(" "+_vm._s(_vm.ex.express.warehouse.country.name)+" ")]:(Object.keys(_vm.ex.express.user).length > 0)?[_vm._v(" "+_vm._s(_vm.getUserContactsBySelectedUser.country.name)+" ")]:[_vm._v(" "+_vm._s(_vm.AUTHORIZED.country.name)+" ")]],2)]),_c('div',{staticClass:"block-table__col block-table__col--20"},[_c('div',{staticClass:"block-table__label"},[_vm._v(" "+_vm._s(_vm.$t('common_zipCode.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content"},[(_vm.ex.express.sendType === _vm.ex.express.sendTypes.warehouse &&
									_vm.ex.express.warehouse && _vm.ex.express.warehouse !== '')?[_vm._v(" "+_vm._s(_vm.ex.express.warehouse.sender_postcode)+" ")]:(Object.keys(_vm.ex.express.user).length > 0)?[_vm._v(" "+_vm._s(_vm.getUserContactsBySelectedUser.zip)+" ")]:[_vm._v(" "+_vm._s(_vm.AUTHORIZED.zip)+" ")]],2)])])])])]):_vm._e(),(_vm.ex.express.type === _vm.NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id)?_c('div',{staticClass:"order-create__row custom-row"},[_c('div',{staticClass:"order-create__col custom-col",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"order-create__table block-table"},[_c('div',{staticClass:"block-table__name"},[_vm._v(" "+_vm._s(_vm.$t('poshta__ServiceType.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__row"},[(_vm.ex.express.serviceType === _vm.ex.express.serviceTypes.doors)?_c('div',{staticClass:"block-table__col block-table__col--20"},[_c('div',{staticClass:"block-table__label"},[_vm._v(" "+_vm._s(_vm.$t('poshta__PersonalAddress.localization_value.value'))+" ")])]):_c('div',{staticClass:"block-table__col block-table__col--66"},[_c('div',{staticClass:"block-table__label"},[_vm._v(" "+_vm._s(_vm.$t('poshta__NovaPoshtaAddress.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content"},[_vm._v(" "+_vm._s(_vm.ex.express.novaPoshtaAddress ? _vm.ex.express.novaPoshtaAddress.warehouse_name : '—')+" ")])])])])])]):_vm._e(),_c('div',{staticClass:"order-create__title-horizontal",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'ex_products',
        ])}}}),_c('TitleHorizontal',{attrs:{"value":_vm.$t('ex_products.localization_value.value'),"rightBtn":true,"rightBtnType":'edit',"rightBtnText":_vm.$t('common_edit.localization_value.value')},on:{"rightBtnClick":function($event){return _vm.$emit('changeStep', 3)}}})],1),_c('div',{staticClass:"order-create__section-label section-label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'ex_products',
        ])}}}),_vm._v(" "+_vm._s(_vm.$t('ex_products.localization_value.value'))+" ")]),_c('div',{staticClass:"custom-row"},_vm._l((_vm.ex.express.products),function(item,index){return _c('div',{key:index,staticClass:"custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"},[_c('div',{staticClass:"confirm-product-card"},[_c('div',{staticClass:"confirm-product-card__items"},[(item.product.proformData.proformCategory !== '')?_c('div',{staticClass:"confirm-product-card__item"},[_c('div',{staticClass:"confirm-product-card__item-name",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                          'proform_category',
                        ])}}}),_vm._v(" "+_vm._s(_vm.$t('proform_category.localization_value.value'))+" ")]),_c('div',{staticClass:"confirm-product-card__item-val"},[_vm._v(" "+_vm._s(item.product.proformData.proformCategory.translationStorage.en.name)+" ")])]):_vm._e(),(_vm.localProformTypes = item.product.proformData.proformTypes)?[_vm._l((_vm.$store.getters.getProformProductInitialize.proformTypes),function(itemType,index){return [(_vm.isUkrainian)?_c('div',{key:index,staticClass:"confirm-product-card__item"},[_c('div',{staticClass:"confirm-product-card__item-name"},[_vm._v(" "+_vm._s(_vm.getProformTypeLabel(_vm.localProformTypes, itemType))+" ")]),_c('div',{staticClass:"confirm-product-card__item-val"},[_vm._v(" "+_vm._s(_vm.localProformTypes[itemType.id].typeValue !== '' ? _vm.localProformTypes[itemType.id].typeValue.value.translationStorage[_vm.proformUserConfig.lang].name : '')+" ")])]):_vm._e(),_c('div',{key:index + '2',staticClass:"confirm-product-card__item"},[_c('div',{staticClass:"confirm-product-card__item-name"},[_vm._v(" "+_vm._s(_vm.localProformTypes[itemType.id].typeName[itemType.translationStorage.en.name + '_En'].name)+" ")]),_c('div',{staticClass:"confirm-product-card__item-val"},[_vm._v(" "+_vm._s(_vm.localProformTypes[itemType.id].typeValue !== '' ? _vm.localProformTypes[itemType.id].typeValue.value.translationStorage.en.name : '')+" ")])])]})]:_vm._e(),_c('div',{staticClass:"confirm-product-card__item",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
								'express_Price',
							])}}}),_c('div',{staticClass:"confirm-product-card__item-name"},[_vm._v(" "+_vm._s(_vm.$t('express_Price.localization_value.value'))+" ")]),_c('div',{staticClass:"confirm-product-card__item-val"},[_vm._v(" "+_vm._s(item.product.price)+" ")])]),_c('div',{staticClass:"confirm-product-card__item",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
								'express_Quantity',
							])}}}),_c('div',{staticClass:"confirm-product-card__item-name"},[_vm._v(" "+_vm._s(_vm.$t('express_Quantity.localization_value.value'))+" ")]),_c('div',{staticClass:"confirm-product-card__item-val"},[_vm._v(" "+_vm._s(item.product.itemsQuantity)+" ")])]),(_vm.ex.express.visibleHSCode && item.product.HSCode !== '')?_c('div',{staticClass:"confirm-product-card__item",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
								'common_HSCode',
							])}}}),_c('div',{staticClass:"confirm-product-card__item-name"},[_vm._v(" "+_vm._s(_vm.$t('common_HSCode.localization_value.value'))+" ")]),_c('div',{staticClass:"confirm-product-card__item-val"},[_vm._v(" "+_vm._s(item.product.HSCode)+" ")])]):_vm._e()],2),(item.product.handMade)?_c('div',{staticClass:"confirm-product-card__bottom",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                          'product_Handmade',
                        ])}}}),_c('StatusIcoBtn',{staticClass:"mr-2",attrs:{"type":'handmade'}}),_vm._v(" "+_vm._s(_vm.$t('product_Handmade.localization_value.value'))+" ")],1):_vm._e()])])}),0),_c('div',{staticClass:"block-table__total site-line-total mt-0 mb-4"},[_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
							'common_TotallyPerOrder',
						])}}}),_vm._v(" "+_vm._s(_vm.$t('common_TotallyPerOrder.localization_value.value'))+" ")]),_c('span',[_vm._v("$"+_vm._s(_vm.calculatePrice))])]),(_vm.ex.express.type === _vm.FEDEX_DELIVERY_SERVICE.id)?[_c('div',{staticClass:"order-create__section-label section-label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
						'express_DocumentsINeed',
					])}}}),_vm._v(" "+_vm._s(_vm.$t('express_DocumentsINeed.localization_value.value'))+" ")]),_c('div',{staticClass:"custom-row"},[_c('div',{staticClass:"custom-col custom-col--33 d-flex align-items-center",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
							'express_ToxicSubstance',
							'express_NeededForSuch',
						])}}}),_c('DefaultCheckbox',{attrs:{"label":_vm.$t('express_ToxicSubstance.localization_value.value'),"disabled":true},on:{"click":function($event){_vm.ex.express.toxicSubstanceControlActCert = !_vm.ex.express.toxicSubstanceControlActCert}},model:{value:(_vm.ex.express.toxicSubstanceControlActCert),callback:function ($$v) {_vm.$set(_vm.ex.express, "toxicSubstanceControlActCert", $$v)},expression:"ex.express.toxicSubstanceControlActCert"}})],1),_c('div',{staticClass:"custom-col custom-col--33 d-flex align-items-center",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
							'express_DropBallTest',
							'express_DropBallTest',
						])}}}),_c('DefaultCheckbox',{attrs:{"label":_vm.$t('express_DropBallTest.localization_value.value'),"disabled":true},on:{"click":function($event){_vm.ex.express.dropBallTestCert = !_vm.ex.express.dropBallTestCert}},model:{value:(_vm.ex.express.dropBallTestCert),callback:function ($$v) {_vm.$set(_vm.ex.express, "dropBallTestCert", $$v)},expression:"ex.express.dropBallTestCert"}})],1)])]:_vm._e(),_c('div',{staticClass:"order-create__title-horizontal",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
							'ex_tabsProductsDetail',
						])}}}),_c('TitleHorizontal',{attrs:{"value":_vm.$t('ex_tabsProductsDetail.localization_value.value'),"rightBtn":true,"rightBtnType":'edit',"rightBtnText":_vm.$t('common_edit.localization_value.value')},on:{"rightBtnClick":function($event){return _vm.$emit('changeStep', 2)}}})],1),_c('div',{staticClass:"order-create__row custom-row"},[_c('div',{staticClass:"order-create__col custom-col"},[_c('div',{staticClass:"order-create__table block-table"},[_c('div',{staticClass:"block-table__name",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
							'ex_personalInformation',
						])}}}),_vm._v(" "+_vm._s(_vm.$t('ex_personalInformation.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__row"},[_c('div',{staticClass:"block-table__col block-table__col--20"},[_c('div',{staticClass:"block-table__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
							'express_ContactName',
						])}}}),_vm._v(" "+_vm._s(_vm.$t('express_ContactName.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content"},[_vm._v(" "+_vm._s(_vm.ex.express.personal.information.personalName)+" ")])]),_c('div',{staticClass:"block-table__col block-table__col--20"},[_c('div',{staticClass:"block-table__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
							'common_phone',
						])}}}),_vm._v(" "+_vm._s(_vm.$t('common_phone.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content"},[_vm._v(" "+_vm._s(_vm.ex.express.personal.information.personalPhone)+" ")])]),_c('div',{staticClass:"block-table__col block-table__col--20"},[_c('div',{staticClass:"block-table__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
							'express_Email',
						])}}}),_vm._v(" "+_vm._s(_vm.$t('express_Email.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content"},[_vm._v(" "+_vm._s(_vm.ex.express.personal.information.personalEmail)+" ")])])])])])]),_c('div',{staticClass:"order-create__row custom-row"},[_c('div',{staticClass:"order-create__col custom-col"},[_c('div',{staticClass:"order-create__table block-table"},[_c('div',{staticClass:"block-table__name",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
							'ex_deliveryAddress',
						])}}}),_vm._v(" "+_vm._s(_vm.$t('ex_deliveryAddress.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__row"},[_c('div',{staticClass:"block-table__col block-table__col--20"},[_c('div',{staticClass:"block-table__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
							'common_address',
						])}}}),_vm._v(" "+_vm._s(_vm.$t('common_address.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content"},[_vm._v(" "+_vm._s(_vm.ex.express.delivery.delivery.address)+" ")])]),(_vm.ex.express.delivery.delivery.address2 !== '')?_c('div',{staticClass:"block-table__col block-table__col--20"},[_c('div',{staticClass:"block-table__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
							'common_addressSecond',
						])}}}),_vm._v(" "+_vm._s(_vm.$t('common_addressSecond.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content"},[_vm._v(" "+_vm._s(_vm.ex.express.delivery.delivery.address2)+" ")])]):_vm._e(),_c('div',{staticClass:"block-table__col block-table__col--20"},[_c('div',{staticClass:"block-table__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
							'common_city',
						])}}}),_vm._v(" "+_vm._s(_vm.$t('common_city.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content"},[_vm._v(" "+_vm._s(_vm.ex.express.delivery.delivery.city)+" ")])]),_c('div',{staticClass:"block-table__col block-table__col--20"},[_c('div',{staticClass:"block-table__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
							'common_region',
						])}}}),_vm._v(" "+_vm._s(_vm.$t('common_region.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content"},[_vm._v(" "+_vm._s(_vm.ex.express.delivery.delivery.region)+" ")])]),_c('div',{staticClass:"block-table__col block-table__col--20"},[_c('div',{staticClass:"block-table__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
							'common_country',
						])}}}),_vm._v(" "+_vm._s(_vm.$t('common_country.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content"},[_vm._v(" "+_vm._s(_vm.ex.express.delivery.delivery.country.label)+" ")])]),_c('div',{staticClass:"block-table__col block-table__col--20",class:{'mt-3': _vm.ex.express.delivery.delivery.address2 !== ''}},[_c('div',{staticClass:"block-table__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
							'common_zipCode',
						])}}}),_vm._v(" "+_vm._s(_vm.$t('common_zipCode.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content"},[_vm._v(" "+_vm._s(_vm.ex.express.delivery.delivery.zip)+" ")])])])])])]),_c('div',{staticClass:"order-create__row custom-row"},[_c('div',{staticClass:"order-create__col custom-col"},[_c('div',{staticClass:"order-create__table block-table"},[_c('div',{staticClass:"block-table__name",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
							'express_DeliveryDate',
						])}}}),_vm._v(" "+_vm._s(_vm.$t('express_DeliveryDate.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__row"},[_c('div',{staticClass:"block-table__col block-table__col--20"},[_c('div',{staticClass:"block-table__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
							'common_date',
						])}}}),_vm._v(" "+_vm._s(_vm.$t('common_date.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.ex.express.deliveryDate,"DD MMM, YYYY"))+" ")])]),_c('div',{staticClass:"block-table__col block-table__col--20"},[_c('div',{staticClass:"block-table__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                          'express_TrackingNumber',
                        ])}}}),_vm._v(" "+_vm._s(_vm.$t('express_TrackingNumber.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content"},[_c('ValueHelper',{attrs:{"value":_vm.ex.express.systemTrackingNumber}})],1)]),_c('div',{staticClass:"block-table__col block-table__col--20"},[_c('div',{staticClass:"block-table__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                          'express_TrackingNumber',
                        ])}}}),_vm._v(" "+_vm._s(_vm.$t('express_TrackingNumberNP.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content"},[_c('ValueHelper',{attrs:{"value":_vm.ex.express.trackingNumber}})],1)]),_c('div',{staticClass:"block-table__col block-table__col--20"},[_c('div',{staticClass:"block-table__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                          'common_vatNumber',
                        ])}}}),_vm._v(" "+_vm._s(_vm.$t('common_vatNumber.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content"},[_c('ValueHelper',{attrs:{"value":_vm.ex.express.vatNumber}})],1)]),_c('div',{staticClass:"block-table__col block-table__col--20"},[_c('div',{staticClass:"block-table__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                          'express_orderPrice',
                        ])}}}),_vm._v(" "+_vm._s(_vm.$t('express_orderPrice.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content"},[_c('ValueHelper',{attrs:{"value":_vm.ex.express.orderPrice}})],1)]),_c('div',{staticClass:"block-table__col block-table__col--20 mt-3"},[_c('div',{staticClass:"block-table__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                          'express_FreightCost',
                        ])}}}),_vm._v(" "+_vm._s(_vm.$t('express_FreightCost.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content"},[_c('ValueHelper',{attrs:{"value":_vm.ex.express.freightCost}})],1)]),(_vm.ex.express.type === _vm.TNT_DELIVERY_SERVICE.id && _vm.ex.express.sendType === _vm.ex.express.sendTypes.sender)?[_c('div',{staticClass:"block-table__col block-table__col--20"},[(!_vm.ex.express.lunchBreak)?_c('div',{staticClass:"block-table__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
							'express_TimeGap',
						])}}}),_vm._v(" "+_vm._s(_vm.$t('express_TimeGap.localization_value.value'))+" ")]):_vm._e(),(_vm.ex.express.lunchBreak)?_c('div',{staticClass:"block-table__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
							'express_BeforeLunch',
						])}}}),_vm._v(" "+_vm._s(_vm.$t('express_BeforeLunch.localization_value.value'))+" ")]):_vm._e(),_c('div',{staticClass:"block-table__content"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.ex.express.timeCourierFrom,"hh:mm"))+" — "+_vm._s(_vm._f("moment")(_vm.ex.express.timeCourierTo,"hh:mm"))+" ")])]),(_vm.ex.express.lunchBreak)?_c('div',{staticClass:"block-table__col block-table__col--20 mt-3"},[_c('div',{staticClass:"block-table__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
										'express_AfterLunch',
									])}}}),_vm._v(" "+_vm._s(_vm.$t('express_AfterLunch.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.ex.express.timeNotAvailableFrom,"hh:mm"))+" — "+_vm._s(_vm._f("moment")(_vm.ex.express.timeNotAvailableTo,"hh:mm"))+" ")])]):_vm._e()]:_vm._e()],2)])])]),_c('div',{staticClass:"order-create__title-horizontal",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
										'ex_packaging',
									])}}}),_c('TitleHorizontal',{attrs:{"value":_vm.$t('ex_packaging.localization_value.value'),"rightBtn":true,"rightBtnType":'edit',"rightBtnText":_vm.$t('common_edit.localization_value.value')},on:{"rightBtnClick":function($event){return _vm.$emit('changeStep', 4)}}})],1),_c('div',{staticClass:"order-create__row custom-row"},[_c('div',{staticClass:"order-create__col custom-col"},[_c('div',{staticClass:"order-create__table block-table"},[_c('div',{staticClass:"block-table__row"},[_c('div',{staticClass:"block-table__col block-table__col--40"},[_c('div',{staticClass:"block-table__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
										'packaging_NamePackage',
									])}}}),_vm._v(" "+_vm._s(_vm.$t('packaging_NamePackage.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content"},[_vm._v(" "+_vm._s(_vm.ex.express.packaging.packaging.name.package_name)+" ")])]),_c('div',{staticClass:"block-table__col block-table__col--fit"},[_c('div',{staticClass:"block-table__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
										'packaging_Weight',
									])}}}),_vm._v(" "+_vm._s(_vm.$t('packaging_Weight.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content"},[_vm._v(" "+_vm._s(_vm.ex.express.packaging.packaging.weight)+"kg ")])]),_c('div',{staticClass:"block-table__col block-table__col--fit"},[_c('div',{staticClass:"block-table__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
										'packaging_Height',
									])}}}),_vm._v(" "+_vm._s(_vm.$t('packaging_Height.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content"},[_vm._v(" "+_vm._s(_vm.ex.express.packaging.packaging.height)+"cm ")])]),_c('div',{staticClass:"block-table__col block-table__col--fit"},[_c('div',{staticClass:"block-table__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
										'packaging_Width',
									])}}}),_vm._v(" "+_vm._s(_vm.$t('packaging_Width.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content"},[_vm._v(" "+_vm._s(_vm.ex.express.packaging.packaging.width)+"cm ")])]),_c('div',{staticClass:"block-table__col block-table__col--fit"},[_c('div',{staticClass:"block-table__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
										'packaging_Length',
									])}}}),_vm._v(" "+_vm._s(_vm.$t('packaging_Length.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content"},[_vm._v(" "+_vm._s(_vm.ex.express.packaging.packaging['length'])+"cm ")])]),_c('div',{staticClass:"block-table__col block-table__col--fit"},[_c('div',{staticClass:"block-table__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
										'express_Volume',
									])}}}),_vm._v(" "+_vm._s(_vm.$t('express_Volume.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content"},[_vm._v(" "+_vm._s(_vm.ex.express.packaging.packaging.volumeWeight)+"kg ")])]),(_vm.ex.getExpressType() === _vm.FEDEX_DELIVERY_SERVICE.id && _vm.isBalticByUserData(_vm.getUserContactDataFromUser(_vm.ex.express.user).country_group))?_c('div',{staticClass:"block-table__col block-table__col--fit"},[_c('div',{staticClass:"block-table__label"},[_vm._v(" "+_vm._s(_vm.$t('express_fedexPacking.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content"},[_vm._v(" "+_vm._s(_vm.$t(`${_vm.ex.express.fedexPackingType.translation}.localization_value.value`))+" ")])]):_vm._e()]),(_vm.ex.express.packaging.packaging.insurance)?_c('div',{staticClass:"block-table__row"},[_c('div',{staticClass:"block-table__col block-table__col--40"},[_c('div',{staticClass:"block-table__content mt-3",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
										'express_INeedInsurance',
									])}}}),_c('DefaultCheckbox',{attrs:{"label":_vm.$t('express_INeedInsurance.localization_value.value') + ' — $' + _vm.ex.express.packaging.packaging.insurancePrice,"value":true,"disabled":true}})],1)])]):_vm._e()])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
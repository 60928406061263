<template>
	<modal
			@close="$emit('close')"
			class="custom-popup-modal custom-popup small-size"
	>
		<template slot="header">
			<div v-if="this.referenceType === 'refBooking'"
					 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
				<div class="admin-edit" @click="editTranslate(['express_BookingReference',])"></div>
				{{$t('express_BookingReference.localization_value.value')}}
			</div>
			<div v-if="this.referenceType === 'ref'"
					 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
				<div class="admin-edit" @click="editTranslate(['express_Reference',])"></div>
				{{$t('express_Reference.localization_value.value')}}
			</div>
		</template>
		<template slot="body">
			<div class="custom-popup__content d-flex align-items-center">
			{{referenceNumber}}
			<LinkButton
				class="ml-2"
				:type="'copy'"
				@click.native="copy"
			/>
			</div>
		</template>
	</modal>
</template>

<script>
	import Modal from '../../../../commonModals/Modal.vue'
	import LinkButton from "../../../../UI/buttons/LinkButton/LinkButton";

	export default {
		name: "ReferencePopup",
		components: {
			Modal,
			LinkButton,
		},

		props: {
			referenceType: String,
			referenceNumber: String,
		},

		data(){
			return {
				text: '',
			}
		},

		mounted() {

		},

		methods: {
			copy() {
				this.copyLink(this.referenceNumber)
				this.openNotify('success', 'common__notificationCopied')
			}
		}

	}

</script>

<style lang="scss">

</style>

<template>
  <div class="fragment">
    <ExpressOrderTableUserHead
            :showFilter="showFilter"
            :navTabs="navTabs"
            :countFilterParams="countFilterParams"
            @toggleFilter="toggleFilter"
            @filter="$emit('filter')"
            @changeTab="(data) => $emit('changeTab', data)"
            @reload="$emit('reload')"
            @selectAll="selectAll"
    />

    <div class="table-filter-wrap">

      <ExpressOrderTableUserFilter
          :showFilter="showFilter"
          :filterGetParams="filterGetParams"
          :navTabs="navTabs"
          @close="closeFilter"
          @changeFilter="data => $emit('changeFilter', data)"
          @resetFilter="$emit('resetFilter')"
      />

      <ExpressOrderTableUserTable
              :countFilterParams="countFilterParams"
              :navTabs="navTabs"
              :selectedNow="selectedNow"
              @showMore="$emit('showMore')"
              @resetFilter="$emit('resetFilter')"
              @reload="$emit('reload')"
              @downloadFiles="type => $emit('downloadFiles', type)"
              @thermalPrint="(data) => $emit('thermalPrint', data)"
      />
    </div>

  </div>
</template>

<script>
  import ExpressOrderTableUserTable from "./ExpressOrderTableUserTable/ExpressOrderTableUserTable.vue";
  import ExpressOrderTableUserFilter from "./ExpressOrderTableUserFilter/ExpressOrderTableUserFilter.vue";
  import ExpressOrderTableUserHead from "./ExpressOrderTableUserHead/ExpressOrderTableUserHead.vue";

  export default {
    name: "ExpressOrderTableUser",

    components: {
      ExpressOrderTableUserHead,
      ExpressOrderTableUserFilter,
      ExpressOrderTableUserTable,
    },

    props: {
      navTabs: Object,
      countFilterParams: Number,
      filterGetParams: Object,
    },

    data() {
      return {
        showFilter: false,
        selectedNow: false,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      selectAll(val) {
        this.selectedNow = val
      },
    }
  }
</script>

<style scoped>

</style>

<template>
  <modal
      @close="$emit('close')"
      class="choose-express-order-modal choose-express-order"
  >
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'common_whatOrderNeed',
        ])"></div>
        {{$t('common_whatOrderNeed.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="choose-express-order__list custom-row">
<!--        <div class="choose-express-order__item custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-100"-->
<!--             v-if="tnt"-->
<!--        >-->
<!--          <router-link-->
<!--            :to="$store.getters.GET_PATHS.expressOrders + tnt"-->
<!--          >-->
<!--            <OrderTypeCardBig-->
<!--              :srcImg="'/img/company-icons-group/tab-tnt.svg'">-->

<!--            </OrderTypeCardBig>-->
<!--          </router-link>-->
<!--        </div>-->
        <div class="choose-express-order__item custom-col custom-col--33 custom-col--md-100"
             v-if="dhl"
        >
          <router-link
            :to="$store.getters.GET_PATHS.expressOrders + dhl"
          >
            <OrderTypeCardBig
              :srcImg="'/img/company-icons-group/tab-dhl.svg'">

            </OrderTypeCardBig>
          </router-link>
        </div>
        <div class="choose-express-order__item custom-col custom-col--33 custom-col--md-100"
             v-if="fedex"
        >
          <router-link
            :to="$store.getters.GET_PATHS.expressOrders + fedex"
          >
            <OrderTypeCardBig
              :srcImg="'/img/company-icons-group/tab-fedex.svg'">

            </OrderTypeCardBig>
          </router-link>
        </div>
        <div class="choose-express-order__item custom-col custom-col--33 custom-col--md-100"
             v-if="npg"
        >
          <router-link
            :to="$store.getters.GET_PATHS.expressOrders + npg"
          >
            <OrderTypeCardBig
              :srcImg="'/img/company-icons-group/logo-poshta-global.svg'">

            </OrderTypeCardBig>
          </router-link>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../commonModals/Modal.vue'
  import OrderTypeCardBig from "../../OrderTypeCardBig/OrderTypeCardBig";

  export default {
    name: "ChooseExpressOrderPopup",
    components: {
      Modal,
      OrderTypeCardBig,
    },

    props: {
      dhl: {
        type: String,
        default: '',
      },
      fedex: {
        type: String,
        default: '',
      },
      tnt: {
        type: String,
        default: '',
      },
      npg: {
        type: String,
        default: '',
      },
    },
  }

</script>

<style lang="scss">
  @import "../../../../scss/mixins/mixins";
  @import "../../../../scss/colors";

  .choose-express-order{

    .modal-component__inner {
      max-width: 846px;
    }

    &__list{
      //display: flex;
      margin: 0 -12px;
    }

    &__item{
      //width: 33.33333%;
      padding:  0 12px;
    }

    &__link{
      display: flex;
      height: 124px;
      justify-content: center;
      align-items: center;
      border: 2px solid $accent;
      box-sizing: border-box;
      border-radius: 5px;
      transition: .15s;
      padding: 30px;

      &:hover{
        background-color: $accent;

        .choose-express-order__ico {
          filter: brightness(0) grayscale(1) invert(1);
        }
      }

    }

    &__ico{
      max-width: 100%;
      max-height: 100%;
      position: relative;
      z-index: 2;
      transition: .15s ease;
    }

  }

</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AccordBlock',[_c('template',{slot:"header"},[_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'ex_packaging',
                        'ex_packagingMaxSize',
                      ])}}}),_vm._v(" "+_vm._s(_vm.$t('ex_packaging.localization_value.value'))+" "),(_vm.ex.express.type === _vm.DHL_DELIVERY_SERVICE.id)?_c('span',{staticClass:"capt"},[_vm._v(" "+_vm._s(_vm.$t('ex_packagingMaxSize.localization_value.value'))+" ")]):_vm._e()])]),_c('template',{slot:"body"},[_c('PackagingBlock',{attrs:{"packaging":_vm.ex.express.packaging,"userId":parseInt(_vm.ex.express.userId),"canUseScale":_vm._.has(_vm.currentPermissions, _vm.PERMISSIONS.USER_CAN_USE_SCALES) || _vm.isAdmin,"allDisabled":_vm.ex.express.needPayFromFastReport}}),(_vm.ex.getExpressType() === _vm.FEDEX_DELIVERY_SERVICE.id && _vm.checkFedexPackaging)?[_c('div',{staticClass:"order-create__product custom-row"},[_c('div',{staticClass:"order-create__product-col custom-col custom-col--50 custom-col--sm-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
						_vm.FEDEX_PACKAGING_TYPES.HIMSELF_FEDEX_PACKAGING_TYPE.translate,
            _vm.FEDEX_PACKAGING_TYPES.FEDEX_PACKAGING_TYPE.translate,
            _vm.FEDEX_PACKAGING_TYPES.ENVELOPE_FEDEX_PACKAGING_TYPE.translate,
					])}}}),_c('DefaultSelect',{attrs:{"options":_vm.fedexPacking,"label":_vm.$t('express_fedexPacking.localization_value.value'),"otherValue":'translation',"selected":_vm.ex.express.fedexPackingType,"clearable":false},on:{"change":_vm.changeFedexPackingType}})],1)])]:_vm._e(),(_vm.ex.getExpressType() !== _vm.NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id)?_c('div',{staticClass:"order-create__row custom-row"},[(_vm.ex.express.type !== _vm.DHL_DELIVERY_SERVICE.id || (!_vm.isAdmin && (_vm.isUserAlternativeContactPoland || _vm.isPoland)) || _vm.isWarehousePyrzowice)?_c('div',{staticClass:"order-create__row custom-col custom-col--33 custom-col--md-100 direction-column",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'express_INeedInsurance',
                        'express_InsurancePrice',
                      ])}}}),_c('DefaultCheckbox',{attrs:{"label":_vm.$t('express_INeedInsurance.localization_value.value')},on:{"click":function($event){_vm.ex.express.packaging.packaging.insurance = !_vm.ex.express.packaging.packaging.insurance}},model:{value:(_vm.ex.express.packaging.packaging.insurance),callback:function ($$v) {_vm.$set(_vm.ex.express.packaging.packaging, "insurance", $$v)},expression:"ex.express.packaging.packaging.insurance"}}),_c('DefaultInput',{staticClass:"mt-4 ml-4",class:{
                  'ui-no-valid': _vm.ex.express.packaging.validation.insurancePrice,
                  required : _vm.ex.express.packaging.packaging.insurance
                },attrs:{"label":_vm.$t('express_InsurancePrice.localization_value.value'),"errorTxt":_vm.$t(`${_vm.ex.express.packaging.validationTranslate.insurancePrice}.localization_value.value`) +
                  ' ' + _vm.ex.express.packaging.validationTxt.insurancePriceMax,"error":_vm.ex.express.packaging.validation.insurancePrice,"disabled":!_vm.ex.express.packaging.packaging.insurance},model:{value:(_vm.ex.express.packaging.packaging.insurancePrice),callback:function ($$v) {_vm.$set(_vm.ex.express.packaging.packaging, "insurancePrice", $$v)},expression:"ex.express.packaging.packaging.insurancePrice"}})],1):_vm._e(),(_vm.$store.getters.getIsAdminRights === 'admin')?_c('div',{staticClass:"order-create__row custom-col custom-col--33 custom-col--md-100 d-flex align-items-end",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'express_TrackingNumber',
                      ])}}}),_c('DefaultInput',{staticClass:"w-100",attrs:{"label":_vm.$t('express_TrackingNumber.localization_value.value')},model:{value:(_vm.ex.express.systemTrackingNumber),callback:function ($$v) {_vm.$set(_vm.ex.express, "systemTrackingNumber", $$v)},expression:"ex.express.systemTrackingNumber"}})],1):_vm._e()]):_vm._e()],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
import {INTERNAL_DELIVERY_SERVICES} from "../../staticData/staticVariables";

export const internalOrdersMixin = {

  methods: {

    saveOrderInternal(draft = false, edit, data){

      let saveType = 'createOrdersInternal'
      if(edit){
        saveType = 'updateOrdersInternal'
      }

      return this.$store.dispatch(saveType, data).then(response => {
        if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

          let id
          if(edit){
            id = response.data.data?.id
          } else {
            id = response.data.data?.order?.id
          }

          if(!draft) {
            return {status: true, id: id}
          }

          if(edit)
            this.openNotify('success', 'common_notificationRecordChanged')
          else
            this.openNotify('success', 'common_notificationRecordCreated')

          return true

        } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
          let errors = response.response.data.errors;

          errors.order_express_id ? this.openNotify('error', {txtServer: errors['order_express_id'][0]}) : false;
          errors.declared_value ? this.openNotify('error', {txtServer: errors['declared_value'][0]}) : false;
          errors.description ? this.openNotify('error', {txtServer: errors['description'][0]}) : false;
          errors.height ? this.openNotify('error', {txtServer: errors['height'][0]}) : false;
          errors['length'] ? this.openNotify('error', {txtServer: errors['length'][0]}) : false;
          errors.places_count ? this.openNotify('error', {txtServer: errors['places_count'][0]}) : false;
          errors.sender_address_ref ? this.openNotify('error', {txtServer: errors['sender_address_ref'][0]}) : false;
          errors.sender_city_ref ? this.openNotify('error', {txtServer: errors['sender_city_ref'][0]}) : false;
          errors.sender_full_address ? this.openNotify('error', {txtServer: errors['sender_full_address'][0]}) : false;
          errors.sender_phone ? this.openNotify('error', {txtServer: errors['sender_phone'][0]}) : false;
          errors.shipped_at ? this.openNotify('error', {txtServer: errors['shipped_at'][0]}) : false;
          errors.volume_general ? this.openNotify('error', {txtServer: errors['volume_general'][0]}) : false;
          errors.volume_weight ? this.openNotify('error', {txtServer: errors['volume_weight'][0]}) : false;
          errors.weight ? this.openNotify('error', {txtServer: errors['weight'][0]}) : false;
          errors.width ? this.openNotify('error', {txtServer: errors['width'][0]}) : false;
          errors.shipping_cost ? this.openNotify('error', {txtServer: errors['shipping_cost'][0]}) : false;

          return false
        } else {
          this.openNotify('error', 'common_notificationUndefinedError')

          return false
        }
      })

    },

    removeOrderInternal(id, orderType = false){
      let text = {
        title: 'poshta_DeleteBelposhtaOrder',
        txt: '',
        yes: 'common_confirmDelete',
        no: 'common_confirmNo'
      }

      let confirm = () => {

        this.$store.dispatch('deleteOrdersInternal', id).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            // let orders = this.$store.getters.getOrdersInternal
            // orders.map((item, index) => {
            //   if(item.id === id) {
            //     orders.splice(index, 1)
            //   }
            // })
            this.$emit('reload')
            this.openNotify('success', 'common_notificationRecordDeleted')

            switch (orderType) {
              case INTERNAL_DELIVERY_SERVICES.BELPOCHTA_DELIVERY_SERVICE.id:
                this.$router.push(this.$store.getters.GET_PATHS.belposhta)
                break
              case INTERNAL_DELIVERY_SERVICES.CDEK_DELIVERY_SERVICE.id:
                this.$router.push(this.$store.getters.GET_PATHS.express)
                break
              case INTERNAL_DELIVERY_SERVICES.UKRPOSHTA_DELIVERY_SERVICE.id:
                this.$router.push(this.$store.getters.GET_PATHS.ukrposhta)
                break
            }

          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    },

    copyOrder(id) {
      return this.$store.dispatch('copyOrdersInternal', id).then(() => {
        this.$emit('reload')
      })
    },


  },

}
